import React from "react";
import Topbar from "../topbar/Topbar";

const Navbar = () => {
  return (
    <>
      <Topbar />
      <div class="menu-wrap">
        <div class="nav-menu">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-2 col-md-2">
                <a href="." title="Logo Webfix">
                  {/* <img
                    class="svg logo-menu d-block"
                    src="./assets/img/logo.svg"
                    alt="logo  Webfix"
                    width="200"
                    height="50"
                  /> */}
                  <img
                    class="svg logo-menu d-block"
                    src="./assets/img/logo-light.svg"
                    alt="logo  Webfix"
                    width="200"
                    height="50"
                  />
                </a>
              </div>
              <nav id="menu" class="col-10 col-md-10">
                <div class="navigation float-end">
                  <button class="menu-toggle" title="Meunu Nav">
                    <span class="icon"></span>
                    <span class="icon"></span>
                    <span class="icon"></span>
                  </button>
                  <div class="main-menu nav navbar-nav navbar-right">
                    <div class="menu-item menu-item-has-children">
                      <a
                        // href="https://webfix.com/"
                        href="/"
                        data-i18n="[html]header.home"
                        title="Home Page"
                      >
                        {" "}
                      </a>
                    </div>
                    <div class="menu-item menu-item-has-children">
                      <a href="/" data-i18n="[html]header.pages">
                        {" "}
                      </a>
                      <div class="sub-menu megamenu-list">
                        <div class="container">
                          <div class="row">
                            <div class="service-list col-md-9 bg-colorstyle">
                              <div class="row">
                                <div class="col-4 service">
                                  <div class="media-body">
                                    <div class="top-head">
                                      <div
                                        class="menu-item c-grey mergecolor"
                                        data-i18n="[html]header.services"
                                      >
                                        {" "}
                                      </div>
                                    </div>
                                    <hr />
                                    <a
                                      href="logo-designing"
                                      class="mergecolor"
                                      data-i18n="[html]submenu.logo"
                                      alt="Logo Designing"
                                    >
                                      {" "}
                                    </a>
                                    <a
                                      href="graphic-designing"
                                      class="mergecolor"
                                      data-i18n="[html]submenu.gd"
                                      title="Graphic Designing"
                                    >
                                      {" "}
                                    </a>
                                    <a
                                      href="ui-ux-development"
                                      class="mergecolor"
                                      data-i18n="[html]submenu.ui"
                                      title="UI-UX Development"
                                    >
                                      {" "}
                                    </a>
                                  </div>
                                </div>
                                <div class="col-4 service">
                                  <div class="media-body">
                                    <div class="top-head">
                                      <div
                                        class="menu-item c-grey mergecolor"
                                        data-i18n="[html]submenu.infrastructure"
                                      ></div>
                                    </div>
                                    <hr />
                                    <a
                                      // href="https://webfix.com/billing/index.php?rp=/store/website-development/kickstart-website"
                                      href="/"
                                      class="mergecolor"
                                      data-i18n="[html]submenu.sw"
                                      title="Kickstart Website"
                                    >
                                      {" "}
                                    </a>
                                    <a
                                      // href="https://webfix.com/billing/index.php?rp=/store/website-development/e-commerce-website"
                                      href="/"
                                      class="mergecolor"
                                      data-i18n="[html]submenu.ecom"
                                      title="E-commerce Website"
                                    >
                                      {" "}
                                    </a>
                                    <a
                                      href="custom-website"
                                      class="mergecolor"
                                      data-i18n="[html]submenu.custom"
                                      title="Custom Website"
                                    >
                                      {" "}
                                    </a>
                                    <a
                                      href="app-development"
                                      class="mergecolor"
                                      title="App Development"
                                    >
                                      {" "}
                                      <span data-i18n="[html]submenu.appsolutions"></span>
                                    </a>
                                    <a
                                      href="ai-development"
                                      class="mergecolor"
                                      title="AI Development"
                                    >
                                      <span data-i18n="[html]submenu.ai"></span>
                                      <span class="badge inside bg-pink">
                                        NEW
                                      </span>
                                    </a>
                                  </div>
                                </div>
                                <div class="col-4 service">
                                  <div class="media-body">
                                    <div class="top-head">
                                      <div
                                        class="menu-item c-grey mergecolor"
                                        data-i18n="[html]submenu.dm"
                                      >
                                        {" "}
                                      </div>
                                    </div>
                                    <hr />
                                    <a
                                      href="seo"
                                      class="mergecolor"
                                      title="SEO"
                                    >
                                      {" "}
                                      <span data-i18n="[html]submenu.seo1">
                                        {" "}
                                      </span>{" "}
                                    </a>
                                    <a
                                      href="ppc"
                                      class="mergecolor"
                                      title="Google Ads/PPC"
                                    >
                                      {" "}
                                      <span data-i18n="[html]submenu.ppc">
                                        {" "}
                                      </span>
                                    </a>
                                    <a
                                      href="ssm"
                                      class="mergecolor"
                                      data-i18n="[html]submenu.ssm"
                                      title="Social Media Marketing"
                                    >
                                      {" "}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="start-offer col-md-3">
                              <div class="inner">
                                <h4
                                  class="title my-3"
                                  style={{ color: "white" }}
                                >
                                  Website Starting <br />
                                  From $222 <br />
                                  Limited Time Offer
                                </h4>
                                <div
                                  class="inner-content"
                                  style={{ color: "white !important" }}
                                >
                                  Delivering quality and functionality at an
                                  unbeatable price.
                                </div>
                                <br />
                                <span
                                  class="m-0"
                                  style={{ color: "white !important" }}
                                >
                                  Before{" "}
                                  <del
                                    class="c-pink"
                                    style={{ color: "white !important" }}
                                  >
                                    $399.00
                                  </del>
                                </span>
                                <br />
                                <h4
                                  class="m-0"
                                  style={{ color: "white !important" }}
                                >
                                  <b>Now</b>{" "}
                                  <b
                                    class="c-pink"
                                    style={{ color: "white !important" }}
                                  >
                                    $222
                                  </b>
                                </h4>
                                <a
                                  // href="https://webfix.com/billing/index.php?rp=/store/website-development/kickstart-website"
                                  href="/"
                                  target="_blank"
                                  rel="noreferrer"
                                  class="btn btn-default-pink-fill mt-4"
                                  title="See Plans"
                                >
                                  See Plans
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="menu-item menu-item-has-children">
                      <a href="/" data-i18n="[html]header.features">
                        Server
                      </a>
                      <ul class="sub-menu bg-colorstyle">
                        <li class="menu-item">
                          <a
                            class="mergecolor"
                            // href="https://webfix.com/billing/cart.php?a=add&domain=register"
                            href="/"
                            data-i18n="[html]submenu.domain"
                          >
                            Domain
                          </a>
                        </li>
                        <li class="menu-item">
                          <a
                            class="mergecolor"
                            // href="https://webfix.com/billing/index.php?rp=/store/hosting"
                            href="/"
                            data-i18n="[html]submenu.hosting1"
                          >
                            Hoisting
                          </a>
                        </li>
                        <li class="menu-item">
                          <a
                            class="mergecolor"
                            // href="https://webfix.com/billing/index.php?rp=/store/ssl-certificates"
                            href="/"
                            data-i18n="[html]submenu.ssl"
                          >
                            {" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="menu-item menu-item-has-children menu-last">
                      <a href="/" data-i18n="[html]header.support">
                        Support
                      </a>
                      <div class="sub-menu megamenu">
                        <div class="container">
                          <div class="row">
                            <div class="service-list col-md-9 bg-colorstyle">
                              <div class="row">
                                <div class="col-4 service">
                                  <div class="media-body">
                                    <a
                                      // href="https://webfix.com/billing/submitticket.php?step=2&deptid=1"
                                      href="/"
                                      class="menu-item mergecolor"
                                      data-i18n="[html]submenu.ot"
                                      title="Open Ticket"
                                    >
                                      {" "}
                                    </a>
                                    <p class="text-muted">
                                      Got an issue? Open a ticket for immediate
                                      assistance!
                                    </p>
                                  </div>
                                </div>
                                <div class="col-4 service">
                                  <div class="media-body">
                                    <a
                                      // href="https://webfix.com/billing/announcements"
                                      href="/"
                                      class="menu-item mergecolor"
                                      data-i18n="[html]submenu.ann"
                                      title="Announcements"
                                    >
                                      {" "}
                                    </a>
                                    <p class="text-muted">
                                      Find news in our comprehensive
                                      announcements library.
                                    </p>
                                  </div>
                                </div>
                                <div class="col-4 service">
                                  <div class="media-body">
                                    <a
                                      href="contact"
                                      class="menu-item mergecolor"
                                      data-i18n="[html]submenu.contact"
                                      title="Contact Us"
                                    >
                                      {" "}
                                    </a>
                                    <p class="text-muted">
                                      <a
                                        href="mailto:info@webfix.com"
                                        style={{
                                          fontWeight: 500,
                                          color: "#4C555D",
                                        }}
                                      >
                                        Email: info@webfix.com{" "}
                                      </a>{" "}
                                      <a
                                        href="tel:+14168888130"
                                        style={{
                                          fontWeight: 500,
                                          color: "#4C555D",
                                        }}
                                      >
                                        Call Us:(416) 888-8130
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <div class=" col-4 service">
                                  <div class="media-body">
                                    {/* <a
                                      href="#"
                                      class="menu-item mergecolor"
                                      data-i18n="[html]submenu.livechat"
                                      title="Live Chat"
                                    >
                                    </a> */}
                                    <p class="text-muted">
                                      Need help now? Connect with our Live Chat
                                      for real-time support!
                                    </p>
                                  </div>
                                </div>
                                <div class="col-4 service">
                                  <div class="media-body">
                                    <div>
                                      <a
                                        href="https://wa.me/+14168888130"
                                        class="menu-item mergecolor"
                                        data-i18n="[html]submenu.wt"
                                        title="Whatsapp Chat"
                                      >
                                        {" "}
                                      </a>
                                    </div>
                                    <p class="text-muted">
                                      Reach out via WhatsApp Chat for quick and
                                      convenient support!
                                    </p>
                                  </div>
                                </div>
                                <div class="col-4 service">
                                  <div class="media-body">
                                    {/* <a
                                      href="#"
                                      class="menu-item mergecolor"
                                      data-i18n="[html]submenu.address"
                                      title="Address"
                                    ></a> */}
                                    <p class="text-muted">
                                      20 Pugsley Ct, Ajax, ON L1Z 0K4, Canada
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="start-offer col-md-3">
                              <div
                                class="inner"
                                style={{ backgroundColor: "#ED2024" }}
                              >
                                <h4 class="title my-3">
                                  Website Premium Support in $39.99
                                </h4>
                                <div class="inner-content">
                                  Experience unparalleled assistance, ensuring
                                  swift and personalized solutions for all your
                                  needs.
                                </div>
                                <a
                                  // href="https://webfix.com/billing/index.php?rp=/store/website-support"
                                  href="/"
                                  target="_blank"
                                  rel="noreferrer"
                                  class="btn btn-default-pink-fill mt-4"
                                  title="Website Support"
                                >
                                  See Plans
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="header__search">
                      <label for="find">
                        <form
                          // action="https://webfix.com/billing/cart.php?a=add&domain=register"
                          action="/"
                          method="post"
                          className="flex justify-center items-center"
                        >
                          <input
                            type="text"
                            name="query"
                            size="20"
                            placeholder="Register A Domain"
                          />
                          <button
                            type="submit"
                            value="Go"
                            class="magnify__icon"
                          >
                            <img src="assets/img/magnifying-glass.svg" alt="" />
                          </button>
                        </form>
                      </label>
                    </div>
                    <div class="tech-box">
                      <img
                        class="svg"
                        src="./assets/img/menu.svg"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasWithBackdrop"
                        aria-controls="offcanvasWithBackdrop"
                        width="32px"
                        height="32px"
                        alt="Menu"
                      />
                    </div>
                    <div class="menu-item">
                      <a
                        // href="https://webfix.com/billing/index.php?rp=/login"
                        href="/"
                        class="pe-0 me-0"
                        title="Login"
                      >
                        <div class="btn btn-default-yellow-fill question">
                          <span data-i18n="[html]header.login"></span>{" "}
                          <i class="fas fa-lock ps-1"></i>{" "}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- ***** NAV MENU MOBILE ****** --> */}
      <div class="menu-wrap mobile">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-6">
              <a
                // href="https://webfix.com"
                href="/"
                aria-label="Home Page"
              >
                <img
                  class="svg logo-menu d-block"
                  src="./assets/img/logo.svg"
                  alt="logo Antler"
                  width="200"
                  height="50"
                />
              </a>
              <a
                // href="https://webfix.com"
                href="/"
                aria-label="Home Page"
              >
                <img
                  class="svg logo-menu d-none"
                  src="./assets/img/logo-light.svg"
                  alt="logo Antler"
                  width="200"
                  height="50"
                />
              </a>
            </div>
            <div class="col-6">
              <nav class="nav-menu float-end d-flex">
                <div class="tech-box">
                  <img
                    class="svg"
                    src="./assets/img/menu.svg"
                    alt=""
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasWithBackdrop"
                    aria-controls="offcanvasWithBackdrop"
                    width="32"
                    height="32"
                  />
                </div>
                <button
                  id="nav-toggle"
                  class="menu-toggle"
                  aria-label="Dropdown Menu"
                >
                  <span class="icon"></span>
                  <span class="icon"></span>
                  <span class="icon"></span>
                </button>
                <div class="main-menu nav navbar-nav bg-seccolorstyle">
                  <div class="menu-item">
                    <a
                      // href="https://webfix.com"
                      href="/"
                      class="mergecolor"
                      data-toggle="dropdown"
                    >
                      Home
                    </a>
                  </div>
                  <div class="menu-item">
                    <a
                      href="/"
                      class="mergecolor dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      What We Offer
                    </a>
                    <div class="dropdown-menu">
                      <a class="dropdown-item menu-item" href="logo-designing">
                        Logo Designing
                      </a>
                      <a
                        class="dropdown-item menu-item"
                        href="graphic-designing"
                      >
                        Graphic Designing
                      </a>
                      <a
                        class="dropdown-item menu-item"
                        href="ui-ux-development"
                      >
                        UI-UX Designing
                      </a>
                      <a
                        class="dropdown-item menu-item"
                        // href="https://webfix.com/billing/index.php?rp=/store/website-development/kickstart-website"
                        href="/"
                      >
                        Kickstart Website
                      </a>
                      <a
                        class="dropdown-item menu-item"
                        // href="https://webfix.com/billing/index.php?rp=/store/website-development/e-commerce-website"
                        href="/"
                      >
                        E-commerce Website
                      </a>
                      <a class="dropdown-item menu-item" href="custom-website">
                        Custom Website
                      </a>
                      <a class="dropdown-item menu-item" href="app-development">
                        App Development
                      </a>
                      <a class="dropdown-item menu-item" href="ai-development">
                        AI Development
                      </a>
                      <a class="dropdown-item menu-item" href="seo">
                        SEO
                      </a>
                      <a class="dropdown-item menu-item" href="ppc">
                        PPC/Google Ads
                      </a>
                      <a class="dropdown-item menu-item" href="ssm">
                        Social Media Marketing
                      </a>
                    </div>
                  </div>
                  <div class="menu-item">
                    <a
                      href="/"
                      class="mergecolor dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      Server
                    </a>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item menu-item"
                        // href="https://webfix.com/billing/cart.php?a=add&domain=register"
                        href="/"
                      >
                        Domains
                      </a>
                      <a
                        class="dropdown-item menu-item"
                        // href="https://webfix.com/billing/index.php?rp=/store/hosting"
                        href="/"
                      >
                        Hosting
                      </a>
                      <a
                        class="dropdown-item menu-item"
                        // href="https://webfix.com/billing/index.php?rp=/store/ssl-certificates"
                        href="/"
                      >
                        SSL Certificates
                      </a>
                    </div>
                  </div>
                  <div class="menu-item menu-last">
                    <a
                      href="/"
                      class="mergecolor dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      Support
                    </a>
                    <div class="dropdown-menu">
                      <a
                        class="dropdown-item menu-item"
                        // href="https://webfix.com/billing/submitticket.php?step=2&deptid=1"
                        href="/"
                      >
                        Open Ticket
                      </a>
                      <a
                        class="dropdown-item menu-item"
                        // href="https://webfix.com/billing/announcements"
                        href="/"
                      >
                        Announcements
                      </a>
                      <a
                        class="dropdown-item menu-item"
                        href="https://wa.me/+14168888130"
                      >
                        Whatsapp Chat
                      </a>
                      <a class="dropdown-item menu-item" href="privacypolicy">
                        Privacy policy
                      </a>
                    </div>
                  </div>
                  <div class="float-left w-100 mt-3 f-18">
                    <p class="c-grey-light seccolor">
                      <small>Email: info@webfix.com</small>{" "}
                    </p>
                  </div>
                  <div class="hehe">
                    <a
                      // href="https://webfix.com/billing/index.php?rp=/login"
                      href="/"
                    >
                      <div
                        class="btn btn-default-yellow-fill mt-3"
                        title="Client Area"
                      >
                        CLIENT AREA
                      </div>
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- ***** Sidebar ****** --> */}
      <div
        class="offcanvas offcanvas-start offcanvas-box bg-colorstyle"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasWithBackdropLabel"
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title mergecolor"
            id="offcanvasWithBackdropLabel"
          >
            Hire an Expert
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="row mb-6">
            <h6 class="mergecolor">Premium Services</h6>
            <div class="col">
              <a
                href="https://calendly.com/webfix-com/sali"
                title="IT Consultant"
              >
                <div class="card mb-4 border-0 upping cursor-p noshadow bg-seccolorstyle">
                  <div class="card-body">
                    <h6 class="card-title text-dark mergecolor">
                      Expert IT Consultant
                    </h6>
                    <p class="card-text text-dark seccolor">
                      <small>
                        Blazing fast & stable hosting infrastructure
                      </small>
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <a
                href="https://calendly.com/webfix-com/sali"
                title="Marketing Consultant"
              >
                <div class="card mb-4 border-0 upping cursor-p noshadow bg-seccolorstyle">
                  <div class="card-body">
                    <h6 class="card-title text-dark mergecolor">
                      Marketing Consultant
                    </h6>
                    <p class="card-text text-dark seccolor">
                      <small>
                        Blazing fast & stable hosting infrastructure
                      </small>
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <a
                // href="https://webfix.com/live-website-fixing"
                href="/"
                title="Live Website Fixing"
              >
                <div class="card mb-4 border-0 upping cursor-p noshadow bg-seccolorstyle">
                  <div class="card-body">
                    <h6 class="card-title text-dark mergecolor">
                      Live Website Fixing
                    </h6>
                    <p class="card-text text-dark seccolor">
                      <small>
                        Blazing fast & stable hosting infrastructure
                      </small>
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <a
                href="https://api.leadconnectorhq.com/widget/form/RfiwGTZCgWXcBUbbq5zl"
                title="Data Entry Resource"
              >
                <div class="card mb-4 border-0 upping cursor-p noshadow bg-seccolorstyle">
                  <div class="card-body">
                    <h6 class="card-title text-dark mergecolor">
                      Data Entry Resource
                    </h6>
                    <p class="card-text text-dark seccolor">
                      <small>
                        Blazing fast & stable hosting infrastructure
                      </small>
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div class="col">
              <div class="card mb-4 border-0 upping cursor-p noshadow bg-seccolorstyle">
                <div class="card-body">
                  <a
                    href="https://api.leadconnectorhq.com/widget/form/F90uxj6kOQ9JnvEhIAv8"
                    title="Product Photography"
                  >
                    <h6 class="card-title text-dark mergecolor">
                      Product Photography
                    </h6>
                    <p class="card-text text-dark seccolor">
                      <small>
                        Blazing fast & stable hosting infrastructure
                      </small>
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div class="col">
              <a
                href="https://api.leadconnectorhq.com/widget/form/F1Ils3Uj6t9fMrDLgF9p"
                title="Payment Solutions"
              >
                <div class="card mb-4 border-0 upping cursor-p noshadow bg-seccolorstyle">
                  <div class="card-body">
                    <h6 class="card-title text-dark mergecolor">
                      Payment Solutions
                    </h6>
                    <p class="card-text text-dark seccolor">
                      <small>
                        Blazing fast & stable hosting infrastructure
                      </small>
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
