import React, { useEffect, useState } from "react";
import "./about.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";


const About = () => {
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const intervsl = setTimeout(() => {
      setLoading(false); 
    }, 1000); 

    return () => {
      clearTimeout(intervsl)
    }
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      {loading ? (
        <Loader /> // Use the Loader component here
      ) : (
        <div class="box-container limit-width">
        {/* <!-- ***** SETTINGS ****** --> */}
        {/* <section id="settings"></section> */}
   
        {/* <!-- ***** FRAME MODE ****** --> */}
        <div class="body-borders" data-border="20">
          <div class="top-border bg-white"></div>
          <div class="right-border bg-white"></div>
          <div class="bottom-border bg-white"></div>
          <div class="left-border bg-white"></div>
        </div>
        {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
        <Navbar />

        {/* <!-- ***** SLIDER ***** --> */}
        {/* <!-- ***** BANNER ***** --> */}
        <div class="top-header exapath-w">
          <div
            class="total-grad-inverse"
            style={{
              background: `url('../assets/img/process/processBanner.png') no-repeat scroll 0 0`,
              backgroundSize: "cover",
            }}
          ></div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="wrapper">
                  <div
                    class="heading"
                    style={{ color: "#212122 !important", textAlign: "center" }}
                  >
                    About Us
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ***** TEAM ***** --> */}
        {/* <!-- About Us Section --> */}
        <section class="custom-split-section">
          <div class="custom-container">
            <div class="custom-image-section">
              <img src="./assets/img/technologies.jpg" alt="computer user" />
            </div>
            <div class="custom-text-section">
              <h2>Who Are We?</h2>
              <h4>Webfix - We Build, Fix & Revamp Websites Since 2002.</h4>
              <p>
                We specialize in web application design and development, SEO,
                content creation, and digital marketing solutions. Our tailored
                digital solutions empower businesses to consistently generate
                revenue.
              </p>
              <button class="custom-btn">Get in Touch</button>
            </div>
          </div>
        </section>

        {/* <!-- Our Team Section --> */}
        <section class="team-section">
          <h2>Our Team</h2>
          <div class="team-container">
            <div class="team-member">
              <img src="./assets/img/ceo.jpg" alt="Team Member 1" />
              <h3>Shahzad Ali</h3>
              <p class="designation">CEO & Founder</p>
              <p class="quote">
                "Never work for money, work for respect & good will, once you
                get it, money will follow you."
              </p>
            </div>
            <div class="team-member">
              <img src="./assets/img/cto.jpg" alt="Team Member 2" />
              <h3>Hammad Irshad</h3>
              <p class="designation">CTO</p>
              <p class="quote">"Design is where art meets functionality."</p>
            </div>
            <div class="team-member">
              <img src="./assets/img/cfo.jpg" alt="Team Member 3" />
              <h3>Jawad Ahmad</h3>
              <p class="designation">CFO</p>
              <p class="quote">"Success is built on strong collaboration."</p>
            </div>
          </div>
        </section>
        {/* <!-- FAQ Section --> */}
        <section class="faq-section">
          <h2>Frequently Asked Questions</h2>
          <div class="faq-container">
            <div class="faq-item">
              <button class="faq-question">What services do you offer?</button>
              <div class="faq-answer">
                <p>
                  We offer web, mobile, and portable application development
                  services, including responsive website creation and digital
                  solutions tailored to enhance user experiences and drive
                  business growth.
                </p>
              </div>
            </div>
            <div class="faq-item">
              <button class="faq-question">
                How long have you been in business?
              </button>
              <div class="faq-answer">
                <p>
                  We have been in business since 2002, providing expert
                  development services and solutions to our clients.
                </p>
              </div>
            </div>
            <div class="faq-item">
              <button class="faq-question">
                What makes your services unique?
              </button>
              <div class="faq-answer">
                <p>
                  Our services are designed to add real business value by
                  creating responsive and effective digital solutions tailored
                  to each client's specific needs.
                </p>
              </div>
            </div>
            <div class="faq-item">
              <button class="faq-question">Do you work with startups?</button>
              <div class="faq-answer">
                <p>
                  Yes, we work with startups and established businesses alike,
                  offering tailored solutions to meet their unique needs and
                  goals.
                </p>
              </div>
            </div>
            <div class="faq-item">
              <button class="faq-question">
                How can I get in touch with you?
              </button>
              <div class="faq-answer">
                <p>
                  You can get in touch with us by phone at
                  <a href="tel:+14168888130" style={{ color: "#ed2024" }}>
                    (416) 888-8130
                  </a>
                  or email us at
                  <a href="mailto:info@webfix.com" style={{ color: "#ed2024" }}>
                    info@webfix.com
                  </a>
                </p>
              </div>
            </div>
            <div class="faq-item">
              <button class="faq-question">
                What is your approach to project management?
              </button>
              <div class="faq-answer">
                <p>
                  We use a collaborative approach to project management,
                  ensuring clear communication and timely delivery of solutions
                  that align with our clients' objectives.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Location Section --> */}
        <section id="our-locations">
          <h2 class="locations-heading">Our Locations</h2>
          <div class="locations-container">
            <div class="location" id="usa"></div>
            <div class="location" id="canada"></div>
            <div class="location" id="uk"></div>
            <div class="moving-dot"></div>
          </div>
        </section>

        {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML ****** --> */}
        <Footer />
      </div>)}

     
      {/* <Footer /> */}
    </>
  );
};

export default About;
