import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Cart = () => {
  return (
    <>
      <div class="box-container limit-width">
        {/* <!-- ***** SETTINGS ****** --> */}
        <section id="settings"></section>
        {/* <!-- ***** LOADING PAGE ****** --> */}
        <div id="spinner-area">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
            <div class="spinner-txt">Antler...</div>
          </div>
        </div>
        {/* <!-- ***** FRAME MODE ****** --> */}
        <div class="body-borders" data-border="20">
          <div class="top-border bg-white"></div>
          <div class="right-border bg-white"></div>
          <div class="bottom-border bg-white"></div>
          <div class="left-border bg-white"></div>
        </div>
        {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
        {/* <header id="header"></header> */}
        <Navbar />
        {/* <!-- ***** BANNER ***** --> */}
        <div class="top-header overlay">
          <div class="lazyload">
            {/* <!--<img src="./assets/img/topbanner04.jpg" class="img-defaultbanner" alt="Background image">--> */}
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="wrapper">
                  <h1 class="heading">Cart</h1>
                  <div class="subheading mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ***** KNOWLEDGEBASE ***** --> */}
        <section class="config cd-main-content pb-80 blog sec-bg2 motpath notoppadding bg-seccolorstyle">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-8 pt-80">
                <div id="sidebar_content" class="wrap-blog">
                  <div class="row">
                    <div class="col-md-12 col-lg-12">
                      <div class="wrapper targetDiv sec-grad-white-to-green noshadow bg-colorstyle">
                        <h1 class="mergecolor">Review & Checkout</h1>
                        <p class="mergecolor">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                        <div class="row">
                          <div class="col-md-12 pt-4">
                            <div class="table-responsive-lg">
                              <table class="table compare">
                                <thead>
                                  <tr class="seccolor">
                                    <th>Product/Options</th>
                                    <th>Qty</th>
                                    <th>Price/Cycle</th>
                                    <th>Edit</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="seccolor">
                                    <td>
                                      <div class="badge bg-purple">
                                        Save 15%
                                      </div>
                                      Business VPS Linux
                                    </td>
                                    <td>
                                      <input
                                        type="number"
                                        value="1"
                                        aria-label="Number"
                                        class="form-control"
                                      />
                                    </td>
                                    <td>
                                      <span class="ltgh">$12.30</span>
                                      <b class="c-green">$9.99</b>
                                    </td>
                                    <td>
                                      <a
                                        href="configurator"
                                        class="me-2 c-pink"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit your product"
                                      >
                                        <i class="fas fa-cog"></i>
                                      </a>
                                      <a
                                        href="/"
                                        aria-label="delete"
                                        data-bs-toggle="modal"
                                        data-bs-target="#myModal"
                                      >
                                        <i class="far fa-trash-alt c-grey seccolor"></i>
                                      </a>
                                    </td>
                                  </tr>
                                  <tr class="seccolor">
                                    <td>yourperfectdomain.com</td>
                                    <td>
                                      <input
                                        type="number"
                                        value="1"
                                        aria-label="Number"
                                        class="form-control"
                                      />
                                    </td>
                                    <td>
                                      <span class="">$16.00</span>
                                    </td>
                                    <td>
                                      <a
                                        href="configurator"
                                        class="me-2 c-pink"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Edit your product"
                                      >
                                        <i class="fas fa-cog"></i>
                                      </a>
                                      <a
                                        href="/"
                                        aria-label="delete"
                                        data-bs-toggle="modal"
                                        data-bs-target="#myModal"
                                      >
                                        <i class="far fa-trash-alt c-grey seccolor"></i>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="col-md-12 pt-4">
                            <div class="cd-filter-block">
                              <div class="seccolor h5">Payment Method</div>
                              <div class="radio-group radios-filter cd-filter-content list w-100">
                                <div class="table-responsive-sm">
                                  <table class="table compare min">
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div class="badge bg-purple me-1">
                                            Select
                                          </div>
                                          <span class="seccolor">
                                            Payments Methods
                                          </span>
                                        </td>
                                        <td>
                                          <div class="cd-select">
                                            <select
                                              aria-label="Payment Methods"
                                              class="select-filter seccolor"
                                            >
                                              <option value="all">
                                                Select Payment Method
                                              </option>
                                              <option value=".data1">
                                                Pay by Bank Transfer
                                              </option>
                                              <option value=".data2">
                                                Pay with Paypal
                                              </option>
                                              <option value=".data3">
                                                Pay by Check
                                              </option>
                                            </select>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div class="badge bg-grey me-1">
                                            Promo
                                          </div>
                                          <span class="seccolor">
                                            Promocode
                                          </span>
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            name="promocode"
                                            aria-label="Promocode"
                                            placeholder="Put your promocode here"
                                            required
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div class="badge bg-grey me-1">
                                            Legal
                                          </div>
                                          <a
                                            class="golink"
                                            href="legal"
                                            target="_blank"
                                          >
                                            Terms (GDPR)
                                          </a>
                                        </td>
                                        <td>
                                          <label class="label-form float-end mb-0"></label>
                                          <div class="my-3">
                                            <input
                                              class="filter"
                                              value="11"
                                              type="checkbox"
                                              aria-label="Checkbox"
                                              id="checkbox1"
                                            />
                                            <label
                                              class="checkbox-label seccolor"
                                              for="checkbox1"
                                            >
                                              Agree with terms and conditions?
                                            </label>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- sidebar --> */}
              <div class="col-md-12 col-lg-4">
                <aside
                  id="sidebar"
                  class="mt-80 sidebar sec-bg1 bg-colorstyle noshadow"
                >
                  <div class="ordersummary mt-0 mb-5">
                    <div class="mergecolor h4">Order Summary</div>
                    <div class="table-responsive-lg">
                      <table class="table">
                        <tbody>
                          <tr class="seccolor">
                            <td>
                              <div class="title-table">Subtotal</div>
                            </td>
                            <td>$25.99 USD</td>
                          </tr>
                          <tr class="seccolor">
                            <td>
                              <div class="title-table">Cycle</div>
                            </td>
                            <td>
                              $9.99 USD Montly <br />
                              $16.00 USD Annually
                            </td>
                          </tr>
                          <tr class="seccolor">
                            <td>
                              <div class="title-table">Total Amount</div>
                            </td>
                            <td>
                              <b>$25.99 USD</b>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <a href="checkout" class="btn btn-default-yellow-fill mb-3">
                    Checkout <i class="fas fa-arrow-alt-circle-right"></i>
                  </a>
                  <a href="index" class="btn btn-default-fill mb-3">
                    Continue Shopping
                  </a>
                </aside>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ***** HELP ***** --> */}
        <section class="services help pt-4 pb-80 bg-seccolorstyle">
          <div class="container">
            <div class="service-wrap">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-colorstyle noshadow">
                    <a href="/" class="help-item">
                      <div class="img">
                        <img
                          class="svg ico"
                          src="./assets/fonts/svg/livechat.svg"
                          height="65"
                          alt=""
                        />
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Live Chat</div>
                        <div class="description seccolor">
                          Lorem Ipsum is simply dummy text printing.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-colorstyle noshadow">
                    <a href="contact" class="help-item">
                      <div class="img">
                        <img
                          class="svg ico"
                          src="./assets/fonts/svg/emailopen.svg"
                          height="65"
                          alt=""
                        />
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Send Ticket</div>
                        <div class="description seccolor">
                          Lorem Ipsum is simply dummy text printing.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-colorstyle noshadow">
                    <a href="knowledgebase-list" class="help-item">
                      <div class="img">
                        <img
                          class="svg ico"
                          src="./assets/fonts/svg/book.svg"
                          height="65"
                          alt=""
                        />
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Knowledge base</div>
                        <div class="description seccolor">
                          Lorem Ipsum is simply dummy text printing.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ***** SMALL MODAL ***** --> */}
        <div class="modal fade" id="myModal" tabIndex="-1">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Remove Item!</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <p class="mb-0">
                  Are you sure you want to <b class="text-danger">remove</b>{" "}
                  this item from your cart?
                </p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-sm btn-default-fill me-1"
                  data-bs-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-default-purple-fill"
                  data-bs-dismiss="modal"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML ***** --> */}
        {/* <footer id="footer"></footer> */}
        <Footer />
      </div>
      {/* <!-- ***** BUTTON GO TOP ***** --> */}
      <a href="/" class="cd-top" title="Go Top">
        <i class="fas fa-angle-up"></i>
      </a>
    </>
  );
};

export default Cart;
