import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Checkout = () => {
  return (
    <>
      <div class="box-container limit-width">
        {/* <!-- ***** SETTINGS ****** --> */}
        <section id="settings"></section>
        {/* <!-- ***** LOADING PAGE ****** --> */}
        <div id="spinner-area">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
            <div class="spinner-txt">Antler...</div>
          </div>
        </div>
        {/* <!-- ***** FRAME MODE ****** --> */}
        <div class="body-borders" data-border="20">
          <div class="top-border bg-white"></div>
          <div class="right-border bg-white"></div>
          <div class="bottom-border bg-white"></div>
          <div class="left-border bg-white"></div>
        </div>
        {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML *****  --> */}
        {/* <header id="header"></header> */}
        <Navbar />
        {/* <!-- ***** BANNER ***** --> */}
        <div class="top-header overlay">
          <div class="lazyload">
            {/* <!--<img src="./assets/img/topbanner04.jpg" class="img-defaultbanner" alt="Checkout">--> */}
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <div class="wrapper">
                  <h1 class="heading">Checkout</h1>
                  <div class="subheading mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ***** KNOWLEDGEBASE ***** --> */}
        <section class="config cd-main-content blog sec-normal sec-bg2 cpupath pt-80 bg-seccolorstyle">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-12">
                <div class="sec-main sec-bg1 tabs bg-seccolorstyle noshadow">
                  <h1 class="mergecolor">Checkout</h1>
                  <p class="mb-5 mergecolor">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                  <div class="tabs-header btn-select-customer">
                    <ul class="btn-group">
                      <li class="btn btn-secondary active mb-2 me-2">
                        Already Registered?
                      </li>
                      <li class="btn btn-secondary">Create a New Account</li>
                    </ul>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="table tabs-item active mb-4">
                        <div class="cd-filter-block">
                          <div class="cd-filter-content">
                            <div class="seccolor h5">
                              Existing Customer Login
                            </div>
                            <div class="row">
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-envelope"></i>
                                </label>
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="Email Address"
                                  required
                                />
                              </div>
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-lock"></i>
                                </label>
                                <input
                                  type="password"
                                  name="password"
                                  placeholder="Password"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="table tabs-item">
                        <div class="cd-filter-block mb-5">
                          <div class="cd-filter-content">
                            <div class="seccolor h5">Sign Up</div>
                            <div class="seccolor">
                              <small>Personal Information</small>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-user-tie"></i>
                                </label>
                                <input
                                  id="inputFirstName"
                                  type="text"
                                  name="firstname"
                                  placeholder="First Name"
                                  value=""
                                />
                              </div>
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-user-tie"></i>
                                </label>
                                <input
                                  id="inputLastName"
                                  type="text"
                                  name="lastname"
                                  placeholder="Last Name"
                                  value=""
                                />
                              </div>
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-envelope"></i>
                                </label>
                                <input
                                  id="inputEmail"
                                  type="email"
                                  name="email"
                                  placeholder="Email Address"
                                  value=""
                                />
                              </div>
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-phone"></i>
                                </label>
                                <input
                                  id="inputPhone"
                                  type="tel"
                                  name="phonenumber"
                                  placeholder="Phone Number"
                                  value=""
                                />
                              </div>
                            </div>
                            <div class="seccolor">
                              <small>Billing Address</small>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-building"></i>
                                </label>
                                <input
                                  id="inputCompanyName"
                                  type="text"
                                  name="companyname"
                                  placeholder="Company Name (Optional)"
                                  value=""
                                />
                              </div>
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="far fa-building"></i>
                                </label>
                                <input
                                  id="inputAddress1"
                                  type="text"
                                  name="address1"
                                  placeholder="Street Address"
                                  value=""
                                />
                              </div>
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-map-marker-alt"></i>
                                </label>
                                <input
                                  id="inputAddress2"
                                  type="text"
                                  name="address2"
                                  placeholder="Street Address 2"
                                  value=""
                                />
                              </div>
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="far fa-building"></i>
                                </label>
                                <input
                                  id="inputCity"
                                  type="text"
                                  name="city"
                                  placeholder="City"
                                  value=""
                                />
                              </div>
                              <div class="col-md-4 position-relative">
                                <div class="cd-select mt-4">
                                  <select
                                    name="state"
                                    class="select-filter"
                                    id="stateselect"
                                  >
                                    <option value="">Select State</option>
                                    <option>Alabama</option>
                                    <option>Alaska</option>
                                    <option>Arizona</option>
                                    <option>Arkansas</option>
                                    <option>California</option>
                                    <option>Colorado</option>
                                    <option>Connecticut</option>
                                    <option>Delaware</option>
                                    <option>District of Columbia</option>
                                    <option>Florida</option>
                                    <option>Georgia</option>
                                    <option>Hawaii</option>
                                    <option>Idaho</option>
                                    <option>Illinois</option>
                                    <option>Indiana</option>
                                    <option>Iowa</option>
                                    <option>Kansas</option>
                                    <option>Kentucky</option>
                                    <option>Louisiana</option>
                                    <option>Maine</option>
                                    <option>Maryland</option>
                                    <option>Massachusetts</option>
                                    <option>Michigan</option>
                                    <option>Minnesota</option>
                                    <option>Mississippi</option>
                                    <option>Missouri</option>
                                    <option>Montana</option>
                                    <option>Nebraska</option>
                                    <option>Nevada</option>
                                    <option>New Hampshire</option>
                                    <option>New Jersey</option>
                                    <option>New Mexico</option>
                                    <option>New York</option>
                                    <option>North Carolina</option>
                                    <option>North Dakota</option>
                                    <option>Ohio</option>
                                    <option>Oklahoma</option>
                                    <option>Oregon</option>
                                    <option>Pennsylvania</option>
                                    <option>Rhode Island</option>
                                    <option>South Carolina</option>
                                    <option>South Dakota</option>
                                    <option>Tennessee</option>
                                    <option>Texas</option>
                                    <option>Utah</option>
                                    <option>Vermont</option>
                                    <option>Virginia</option>
                                    <option>Washington</option>
                                    <option>West Virginia</option>
                                    <option>Wisconsin</option>
                                    <option>Wyoming</option>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-4 position-relative">
                                <label>
                                  <i class="fas fa-certificate"></i>
                                </label>
                                <input
                                  id="inputPostcode"
                                  type="text"
                                  name="postcode"
                                  placeholder="Postcode"
                                  value=""
                                />
                              </div>
                              <div class="col-md-4 position-relative">
                                <div class="cd-select mt-4">
                                  <select
                                    name="country"
                                    id="inputCountry"
                                    class="select-filter"
                                  >
                                    <option value="AF">Afghanistan</option>
                                    <option value="AX">Aland Islands</option>
                                    <option value="AL">Albania</option>
                                    <option value="DZ">Algeria</option>
                                    <option value="AS">American Samoa</option>
                                    <option value="AD">Andorra</option>
                                    <option value="AO">Angola</option>
                                    <option value="AI">Anguilla</option>
                                    <option value="AQ">Antarctica</option>
                                    <option value="AG">
                                      Antigua And Barbuda
                                    </option>
                                    <option value="AR">Argentina</option>
                                    <option value="AM">Armenia</option>
                                    <option value="AW">Aruba</option>
                                    <option value="AU">Australia</option>
                                    <option value="AT">Austria</option>
                                    <option value="AZ">Azerbaijan</option>
                                    <option value="BS">Bahamas</option>
                                    <option value="BH">Bahrain</option>
                                    <option value="BD">Bangladesh</option>
                                    <option value="BB">Barbados</option>
                                    <option value="BY">Belarus</option>
                                    <option value="BE">Belgium</option>
                                    <option value="BZ">Belize</option>
                                    <option value="BJ">Benin</option>
                                    <option value="BM">Bermuda</option>
                                    <option value="BT">Bhutan</option>
                                    <option value="BO">Bolivia</option>
                                    <option value="BA">
                                      Bosnia And Herzegovina
                                    </option>
                                    <option value="BW">Botswana</option>
                                    <option value="BV">Bouvet Island</option>
                                    <option value="BR">Brazil</option>
                                    <option value="IO">
                                      British Indian Ocean Territory
                                    </option>
                                    <option value="BN">
                                      Brunei Darussalam
                                    </option>
                                    <option value="BG">Bulgaria</option>
                                    <option value="BF">Burkina Faso</option>
                                    <option value="BI">Burundi</option>
                                    <option value="KH">Cambodia</option>
                                    <option value="CM">Cameroon</option>
                                    <option value="CA">Canada</option>
                                    <option value="CV">Cape Verde</option>
                                    <option value="KY">Cayman Islands</option>
                                    <option value="CF">
                                      Central African Republic
                                    </option>
                                    <option value="TD">Chad</option>
                                    <option value="CL">Chile</option>
                                    <option value="CN">China</option>
                                    <option value="CX">Christmas Island</option>
                                    <option value="CC">
                                      Cocos (Keeling) Islands
                                    </option>
                                    <option value="CO">Colombia</option>
                                    <option value="KM">Comoros</option>
                                    <option value="CG">Congo</option>
                                    <option value="CD">
                                      Congo, Democratic Republic
                                    </option>
                                    <option value="CK">Cook Islands</option>
                                    <option value="CR">Costa Rica</option>
                                    <option value="CI">Cote D'Ivoire</option>
                                    <option value="HR">Croatia</option>
                                    <option value="CU">Cuba</option>
                                    <option value="CW">Curacao</option>
                                    <option value="CY">Cyprus</option>
                                    <option value="CZ">Czech Republic</option>
                                    <option value="DK">Denmark</option>
                                    <option value="DJ">Djibouti</option>
                                    <option value="DM">Dominica</option>
                                    <option value="DO">
                                      Dominican Republic
                                    </option>
                                    <option value="EC">Ecuador</option>
                                    <option value="EG">Egypt</option>
                                    <option value="SV">El Salvador</option>
                                    <option value="GQ">
                                      Equatorial Guinea
                                    </option>
                                    <option value="ER">Eritrea</option>
                                    <option value="EE">Estonia</option>
                                    <option value="ET">Ethiopia</option>
                                    <option value="FK">
                                      Falkland Islands (Malvinas)
                                    </option>
                                    <option value="FO">Faroe Islands</option>
                                    <option value="FJ">Fiji</option>
                                    <option value="FI">Finland</option>
                                    <option value="FR">France</option>
                                    <option value="GF">French Guiana</option>
                                    <option value="PF">French Polynesia</option>
                                    <option value="TF">
                                      French Southern Territories
                                    </option>
                                    <option value="GA">Gabon</option>
                                    <option value="GM">Gambia</option>
                                    <option value="GE">Georgia</option>
                                    <option value="DE">Germany</option>
                                    <option value="GH">Ghana</option>
                                    <option value="GI">Gibraltar</option>
                                    <option value="GR">Greece</option>
                                    <option value="GL">Greenland</option>
                                    <option value="GD">Grenada</option>
                                    <option value="GP">Guadeloupe</option>
                                    <option value="GU">Guam</option>
                                    <option value="GT">Guatemala</option>
                                    <option value="GG">Guernsey</option>
                                    <option value="GN">Guinea</option>
                                    <option value="GW">Guinea-Bissau</option>
                                    <option value="GY">Guyana</option>
                                    <option value="HT">Haiti</option>
                                    <option value="HM">
                                      Heard Island &amp; Mcdonald Islands
                                    </option>
                                    <option value="VA">
                                      Holy See (Vatican City State)
                                    </option>
                                    <option value="HN">Honduras</option>
                                    <option value="HK">Hong Kong</option>
                                    <option value="HU">Hungary</option>
                                    <option value="IS">Iceland</option>
                                    <option value="IN">India</option>
                                    <option value="ID">Indonesia</option>
                                    <option value="IR">
                                      Iran, Islamic Republic Of
                                    </option>
                                    <option value="IQ">Iraq</option>
                                    <option value="IE">Ireland</option>
                                    <option value="IM">Isle Of Man</option>
                                    <option value="IL">Israel</option>
                                    <option value="IT">Italy</option>
                                    <option value="JM">Jamaica</option>
                                    <option value="JP">Japan</option>
                                    <option value="JE">Jersey</option>
                                    <option value="JO">Jordan</option>
                                    <option value="KZ">Kazakhstan</option>
                                    <option value="KE">Kenya</option>
                                    <option value="KI">Kiribati</option>
                                    <option value="KR">Korea</option>
                                    <option value="KW">Kuwait</option>
                                    <option value="KG">Kyrgyzstan</option>
                                    <option value="LA">
                                      Lao People's Democratic Republic
                                    </option>
                                    <option value="LV">Latvia</option>
                                    <option value="LB">Lebanon</option>
                                    <option value="LS">Lesotho</option>
                                    <option value="LR">Liberia</option>
                                    <option value="LY">
                                      Libyan Arab Jamahiriya
                                    </option>
                                    <option value="LI">Liechtenstein</option>
                                    <option value="LT">Lithuania</option>
                                    <option value="LU">Luxembourg</option>
                                    <option value="MO">Macao</option>
                                    <option value="MK">Macedonia</option>
                                    <option value="MG">Madagascar</option>
                                    <option value="MW">Malawi</option>
                                    <option value="MY">Malaysia</option>
                                    <option value="MV">Maldives</option>
                                    <option value="ML">Mali</option>
                                    <option value="MT">Malta</option>
                                    <option value="MH">Marshall Islands</option>
                                    <option value="MQ">Martinique</option>
                                    <option value="MR">Mauritania</option>
                                    <option value="MU">Mauritius</option>
                                    <option value="YT">Mayotte</option>
                                    <option value="MX">Mexico</option>
                                    <option value="FM">
                                      Micronesia, Federated States Of
                                    </option>
                                    <option value="MD">Moldova</option>
                                    <option value="MC">Monaco</option>
                                    <option value="MN">Mongolia</option>
                                    <option value="ME">Montenegro</option>
                                    <option value="MS">Montserrat</option>
                                    <option value="MA">Morocco</option>
                                    <option value="MZ">Mozambique</option>
                                    <option value="MM">Myanmar</option>
                                    <option value="NA">Namibia</option>
                                    <option value="NR">Nauru</option>
                                    <option value="NP">Nepal</option>
                                    <option value="NL">Netherlands</option>
                                    <option value="AN">
                                      Netherlands Antilles
                                    </option>
                                    <option value="NC">New Caledonia</option>
                                    <option value="NZ">New Zealand</option>
                                    <option value="NI">Nicaragua</option>
                                    <option value="NE">Niger</option>
                                    <option value="NG">Nigeria</option>
                                    <option value="NU">Niue</option>
                                    <option value="NF">Norfolk Island</option>
                                    <option value="MP">
                                      Northern Mariana Islands
                                    </option>
                                    <option value="NO">Norway</option>
                                    <option value="OM">Oman</option>
                                    <option value="PK">Pakistan</option>
                                    <option value="PW">Palau</option>
                                    <option value="PS">
                                      Palestine, State of
                                    </option>
                                    <option value="PA">Panama</option>
                                    <option value="PG">Papua New Guinea</option>
                                    <option value="PY">Paraguay</option>
                                    <option value="PE">Peru</option>
                                    <option value="PH">Philippines</option>
                                    <option value="PN">Pitcairn</option>
                                    <option value="PL">Poland</option>
                                    <option value="PT">Portugal</option>
                                    <option value="PR">Puerto Rico</option>
                                    <option value="QA">Qatar</option>
                                    <option value="RE">Reunion</option>
                                    <option value="RO">Romania</option>
                                    <option value="RU">
                                      Russian Federation
                                    </option>
                                    <option value="RW">Rwanda</option>
                                    <option value="BL">Saint Barthelemy</option>
                                    <option value="SH">Saint Helena</option>
                                    <option value="KN">
                                      Saint Kitts And Nevis
                                    </option>
                                    <option value="LC">Saint Lucia</option>
                                    <option value="MF">Saint Martin</option>
                                    <option value="PM">
                                      Saint Pierre And Miquelon
                                    </option>
                                    <option value="VC">
                                      Saint Vincent And Grenadines
                                    </option>
                                    <option value="WS">Samoa</option>
                                    <option value="SM">San Marino</option>
                                    <option value="ST">
                                      Sao Tome And Principe
                                    </option>
                                    <option value="SA">Saudi Arabia</option>
                                    <option value="SN">Senegal</option>
                                    <option value="RS">Serbia</option>
                                    <option value="SC">Seychelles</option>
                                    <option value="SL">Sierra Leone</option>
                                    <option value="SG">Singapore</option>
                                    <option value="SK">Slovakia</option>
                                    <option value="SI">Slovenia</option>
                                    <option value="SB">Solomon Islands</option>
                                    <option value="SO">Somalia</option>
                                    <option value="ZA">South Africa</option>
                                    <option value="GS">
                                      South Georgia And Sandwich Isl.
                                    </option>
                                    <option value="ES">Spain</option>
                                    <option value="LK">Sri Lanka</option>
                                    <option value="SD">Sudan</option>
                                    <option value="SR">Suriname</option>
                                    <option value="SJ">
                                      Svalbard And Jan Mayen
                                    </option>
                                    <option value="SZ">Swaziland</option>
                                    <option value="SE">Sweden</option>
                                    <option value="CH">Switzerland</option>
                                    <option value="SY">
                                      Syrian Arab Republic
                                    </option>
                                    <option value="TW">Taiwan</option>
                                    <option value="TJ">Tajikistan</option>
                                    <option value="TZ">Tanzania</option>
                                    <option value="TH">Thailand</option>
                                    <option value="TL">Timor-Leste</option>
                                    <option value="TG">Togo</option>
                                    <option value="TK">Tokelau</option>
                                    <option value="TO">Tonga</option>
                                    <option value="TT">
                                      Trinidad And Tobago
                                    </option>
                                    <option value="TN">Tunisia</option>
                                    <option value="TR">Turkey</option>
                                    <option value="TM">Turkmenistan</option>
                                    <option value="TC">
                                      Turks And Caicos Islands
                                    </option>
                                    <option value="TV">Tuvalu</option>
                                    <option value="UG">Uganda</option>
                                    <option value="UA">Ukraine</option>
                                    <option value="AE">
                                      United Arab Emirates
                                    </option>
                                    <option value="GB">United Kingdom</option>
                                    <option value="US" selected="">
                                      United States
                                    </option>
                                    <option value="UM">
                                      United States Outlying Islands
                                    </option>
                                    <option value="UY">Uruguay</option>
                                    <option value="UZ">Uzbekistan</option>
                                    <option value="VU">Vanuatu</option>
                                    <option value="VE">Venezuela</option>
                                    <option value="VN">Viet Nam</option>
                                    <option value="VG">
                                      Virgin Islands, British
                                    </option>
                                    <option value="VI">
                                      Virgin Islands, U.S.
                                    </option>
                                    <option value="WF">
                                      Wallis And Futuna
                                    </option>
                                    <option value="EH">Western Sahara</option>
                                    <option value="YE">Yemen</option>
                                    <option value="ZM">Zambia</option>
                                    <option value="ZW">Zimbabwe</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="seccolor">
                              <small>Account Security</small>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-lock"></i>
                                </label>
                                <input
                                  id="inputNewPassword1"
                                  type="password"
                                  name="password"
                                  placeholder="Password"
                                  data-error-threshold="50"
                                  data-warning-threshold="75"
                                  value=""
                                />
                              </div>
                              <div class="col-md-6 position-relative">
                                <label>
                                  <i class="fas fa-lock"></i>
                                </label>
                                <input
                                  id="inputNewPassword2"
                                  type="password"
                                  name="password2"
                                  placeholder="Confirm Password"
                                  value=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cd-filter-block m-0">
                        <div class="cd-filter-content">
                          <div class="seccolor h5">Payment Details</div>
                          <div class="radio-group radios-filter cd-filter-content list w-100">
                            <div class="table-responsive-sm">
                              <table class="table compare min mb-5">
                                <tbody>
                                  <tr>
                                    <td class="border-0">
                                      <div class="badge bg-purple me-1">
                                        Value
                                      </div>
                                      <span class="seccolor">
                                        Total Due Today
                                      </span>
                                    </td>
                                    <td class="border-0">
                                      <b class="float-end h4 seccolor">
                                        $25.99 USD
                                      </b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="border-0">
                                      <div class="badge bg-grey me-1">
                                        Select
                                      </div>
                                      <span class="seccolor">
                                        Payments Methods
                                      </span>
                                    </td>
                                    <td class="border-0">
                                      <div class="cd-select">
                                        <select
                                          aria-label="Select Payment"
                                          class="select-filter seccolor"
                                        >
                                          <option value="all">
                                            Select Payment Method
                                          </option>
                                          <option value=".data1">
                                            Pay by Bank Transfer
                                          </option>
                                          <option value=".data2">
                                            Pay with Paypal
                                          </option>
                                          <option value=".data3">
                                            Pay by Check
                                          </option>
                                        </select>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="border-0 seccolor">
                                      Additional Notes
                                    </td>
                                    <td class="border-0">
                                      <div class="form-group">
                                        <textarea
                                          name="notes"
                                          class="form-control"
                                          rows="3"
                                          placeholder="You can enter any additional notes or information you want included with your order here..."
                                        ></textarea>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <button
                                type="submit"
                                id="btnCompleteOrder"
                                class="btn btn-default-yellow-fill mb-1 disable-on-click spinner-on-click"
                              >
                                Complete Order
                                <i class="fas fa-arrow-alt-circle-right"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ***** HELP ***** --> */}
        <section class="services help pt-4 pb-80 bg-seccolorstyle">
          <div class="container">
            <div class="service-wrap">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-colorstyle noshadow">
                    <a href="/" class="help-item">
                      <div class="img">
                        <img
                          class="svg ico"
                          src="./assets/fonts/svg/livechat.svg"
                          height="65"
                          alt=""
                        />
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Live Chat</div>
                        <div class="description seccolor">
                          Lorem Ipsum is simply dummy text printing.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-colorstyle noshadow">
                    <a href="contact" class="help-item">
                      <div class="img">
                        <img
                          class="svg ico"
                          src="./assets/fonts/svg/emailopen.svg"
                          height="65"
                          alt=""
                        />
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Send Ticket</div>
                        <div class="description seccolor">
                          Lorem Ipsum is simply dummy text printing.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-colorstyle noshadow">
                    <a href="knowledgebase-list" class="help-item">
                      <div class="img">
                        <img
                          class="svg ico"
                          src="./assets/fonts/svg/book.svg"
                          height="65"
                          alt=""
                        />
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Knowledge base</div>
                        <div class="description seccolor">
                          Lorem Ipsum is simply dummy text printing.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML ***** --> */}
        {/* <footer id="footer"></footer> */}
        <Footer />
      </div>
      {/* <!-- ***** BUTTON GO TOP ***** --> */}
      <a href="/" class="cd-top" title="Go Top">
        <i class="fas fa-angle-up"></i>
      </a>
    </>
  );
};

export default Checkout;
