import React from "react";

import "./contact.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Contact = () => {
  return (
    <div class="box-container limit-width">
      {/* <!-- ***** SETTINGS ****** --> */}
      <section id="settings"> </section>
      {/* <!-- ***** LOADING PAGE ****** --> */}
      <div id="spinner-area">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
          <div class="spinner-txt">WebFix...</div>
        </div>
      </div>
      {/* <!-- ***** FRAME MODE ****** --> */}
      <div class="body-borders" data-border="20">
        <div class="top-border bg-white"></div>
        <div class="right-border bg-white"></div>
        <div class="bottom-border bg-white"></div>
        <div class="left-border bg-white"></div>
      </div>
      {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
      {/* <header id="header"> </header> */}
      <Navbar />
      {/* <!-- ***** BANNER ***** --> */}
      <div class="top-header total-grad-inverse">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="wrapper">
                <h1 class="heading">Contact Us</h1>
                <div class="subheading">
                  Don't worry! We have support premium 24/7/365. We are looking
                  forward waiting for your contact.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ***** LOCATION ***** --> */}
      <div class="branches-section">
        <div class="container">
          <div class="branches-title">
            <h2>Our Global Presence</h2>
          </div>
          <div class="branches-wrapper">
            <div class="branch-box">
              <div class="branch-content">
                <h3>USA</h3>
                <p>4245 N Central Expy, Dallas, Texas 75205, US</p>
              </div>
            </div>
            <div class="branch-box">
              <div class="branch-content">
                <h3>Canada</h3>
                <p>50 Kennedy Rd S, 55, Brampton, Ontario L6W, CA</p>
              </div>
            </div>
            <div class="branch-box">
              <div class="branch-content">
                <h3>UK</h3>
                <p>1 Brompton Road, Stretford, Manchester M32 9QR, GB</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contact_us_6">
        <div class="responsive-container-block container">
          <form class="form-box">
            <div class="container-block form-wrapper">
              <div class="mob-text">
                <p class="text-blk contactus-head">Get in Touch</p>
                <p class="text-blk contactus-subhead">
                  We’d love to hear from you! Please fill out the form below,
                  and one of our team members will get back to you as soon as
                  possible.
                </p>
              </div>
              <div class="responsive-container-block" id="i2cbk">
                <div
                  class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                  id="i10mt-3"
                >
                  <p class="text-blk input-title">FIRST NAME</p>
                  <input
                    class="input"
                    id="ijowk-3"
                    name="FirstName"
                    placeholder="Please enter first name..."
                  />
                </div>
                <div
                  class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                  id="ip1yp"
                >
                  <p class="text-blk input-title">EMAIL</p>
                  <input
                    class="input"
                    id="ipmgh-3"
                    name="Email"
                    placeholder="Please enter email..."
                  />
                </div>
                <div
                  class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                  id="ih9wi"
                >
                  <p class="text-blk input-title">PHONE NUMBER</p>
                  <input
                    class="input"
                    id="imgis-3"
                    name="PhoneNumber"
                    placeholder="Please enter phone number..."
                  />
                </div>
                <div
                  class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
                  id="i634i-3"
                >
                  <p class="text-blk input-title">WHAT DO YOU HAVE IN MIND ?</p>
                  <textarea
                    class="textinput"
                    id="i5vyy-3"
                    placeholder="Please enter query..."
                  ></textarea>
                </div>
              </div>
              <button class="submit-btn" id="w-c-s-bgc_p-1-dm-id-2">
                Submit
              </button>
            </div>
          </form>
          <div
            class="responsive-cell-block wk-desk-7 wk-ipadp-12 wk-tab-12 wk-mobile-12"
            id="i772w"
          >
            <div class="map-part">
              <p
                class="text-blk contactus-head"
                id="w-c-s-fc_p-1-dm-id"
                style={{ fontSize: "20px" }}
              >
                Reach Us Out
              </p>
              <p class="text-blk map-contactus-subhead">
                We’re here to help with any questions, concerns and worries. Our
                dedicated support team is ready to assist you.
              </p>

              <div class="map-box container-block">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2885.0122533892!2d-79.74162272441774!3d43.68950865008257!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3fdb77d405cb%3A0xbeb94e7ea9d116d!2sWebFix%20(We%20Build%2C%20Revamp%20%26%20Fix%20Websites%20Since%202002)!5e0!3m2!1sen!2s!4v1724359237385!5m2!1sen!2s"
                  width="600"
                  height="450"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="hello"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML ***** --> */}
      {/* <footer id="footer"> </footer> */}
      <Footer />
    </div>
  );
};

export default Contact;
