import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const CompanyPolicy = () => {
  return (
    <div class="box-container limit-width">
      {/* <!-- ***** SETTINGS ****** --> */}
      <section id="settings"> </section>
      {/* <!-- ***** LOADING PAGE ****** --> */}
      <div id="spinner-area">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
          <div class="spinner-txt">Webfix...</div>
        </div>
      </div>
      {/* <!-- ***** FRAME MODE ****** --> */}
      <div class="body-borders" data-border="20">
        <div class="top-border bg-white"></div>
        <div class="right-border bg-white"></div>
        <div class="bottom-border bg-white"></div>
        <div class="left-border bg-white"></div>
      </div>
      {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
      {/* <header id="header"> </header> */}
      <Navbar />
      {/* <!-- ***** BANNER ***** --> */}
      <div class="top-header exapath-w">
        <div class="total-grad-inverse-new"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="wrapper">
                <div class="heading" style={{ color: "#212122 !important" }}>
                  Company Policy
                </div>
                <div class="subheading" style={{ color: "#212122 !important" }}>
                  Guiding Principles: Explore Our Commitment to Integrity and
                  Excellence
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ***** YOUR CONTENT ***** --> */}
      <section class="balancing sec-normal bg-white">
        <div class="h-services">
          <div class="container">
            <div class="randomline">
              <div class="bigline"></div>
              <div class="smallline"></div>
            </div>
            <div class="row">
              <div class="col-md-12 text-start">
                <h2
                  class="section-heading"
                  style={{ color: "#212122 !important" }}
                >
                  The Company Policy
                </h2>
                <ol
                  class="section-subheading mb-0"
                  style={{ listStyleType: "decimal" }}
                >
                  <li>
                    WebFix does not work with certain business types, including
                    those involving adult content, the sale or consumption of
                    drugs, online gambling, the sale of weapons, financial or
                    credit institutions, realtors, mortgage broker, Insurance
                    agents, ammunition or explosives, and the sale of tobacco.
                    Additionally, online pharmacies or dating sites are also
                    excluded.{" "}
                  </li>
                  <li>
                    Support is available on working days during regular business
                    hours; weekends are not covered.
                  </li>
                  <li>
                    Any abusive behavior exhibited by clients will result in the
                    immediate cancellation of services without any refund.
                  </li>
                  <li>
                    Clients must specify at the project's outset whether they
                    require a Non-Disclosure Agreement (NDA).
                  </li>
                  <li>
                    WebFix will exclusively undertake projects that adhere to
                    applicable local, state, and federal laws, as well as
                    industry regulations.{" "}
                  </li>
                  <li>
                    WebFix will operate within the parameters of all relevant
                    federal, provincial, and territorial laws pertaining to its
                    business activities.{" "}
                  </li>
                  <li>
                    For any client project, WebFix will solely use authorized
                    software, licensed products, licensed themes, and licensed
                    or royalty-free graphics and content.{" "}
                  </li>
                  <li>
                    In cases where WebFix employs open source software, strict
                    adherence to pertinent licenses, including fulfilling
                    required contributions, will be ensured.{" "}
                  </li>
                  <li>
                    WebFix will comply with the Canada Anti-Spam Legislation
                    (CASL) in relation to electronic communications, including
                    email marketing and text messages.
                  </li>
                  <li>
                    WebFix will exclusively engage in business transactions with
                    entities possessing proper HST/GST registration or furnished
                    with valid and compliant invoices.{" "}
                  </li>
                  <li>
                    Should you have inquiries or concerns regarding our company
                    policies, please reach out to us at support@webfixinc.com{" "}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML ***** --> */}
      {/* <footer id="footer"> </footer> */}
      <Footer />
    </div>
  );
};

export default CompanyPolicy;
