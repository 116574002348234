import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const PaymentPolicy = () => {
  return (
    <div class="box-container limit-width">
      {/* <!-- ***** SETTINGS ****** --> */}
      <section id="settings"> </section>
      {/* <!-- ***** LOADING PAGE ****** --> */}
      <div id="spinner-area">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
          <div class="spinner-txt">Webfix...</div>
        </div>
      </div>
      {/* <!-- ***** FRAME MODE ****** --> */}
      <div class="body-borders" data-border="20">
        <div class="top-border bg-white"></div>
        <div class="right-border bg-white"></div>
        <div class="bottom-border bg-white"></div>
        <div class="left-border bg-white"></div>
      </div>
      {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
      {/* <header id="header"> </header> */}
      <Navbar />
      {/* <!-- ***** BANNER ***** --> */}
      <div class="top-header exapath-w">
        <div class="total-grad-inverse-new"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="wrapper">
                <div class="heading">Payment Policy</div>
                <div class="subheading" style={{ color: "#212122 !important" }}>
                  Secure Transactions: Everything You Need to Know About Our
                  Payment Policy
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ***** YOUR CONTENT ***** --> */}
      <section class="balancing sec-normal bg-white">
        <div class="h-services">
          <div class="container">
            <div class="randomline">
              <div class="bigline"></div>
              <div class="smallline"></div>
            </div>
            <div class="row">
              <div class="col-md-12 text-start">
                <h2
                  class="section-heading"
                  style={{ color: "#212122 !important" }}
                >
                  The Payment Policy
                </h2>
                <p class="section-subheading mb-0">
                  WebFix, Inc. accepts the following payment options: cash,
                  check, PayPal, Visa, MasterCard, and American Express.
                  Returned checks will be subject to a penalty of $25 or 10% of
                  the total amount, whichever is higher. Service cancellation
                  requests must be made online or over the phone before the
                  invoice is due. <br />
                  <br />
                  We will not use our company credit card or any other payment
                  method to cover expenses for client purchases or payments
                  related to ads accounts. If a client does not have a valid
                  debit card, Visa, Mastercard, or any other valid card, we can
                  facilitate the transaction using our company card. However, a
                  handling fee of 6.99% on the transacted amount will apply. The
                  client is required to make the full advance payment for the
                  designated amount. <br />
                  <br />
                  If the client is unsatisfied with our service, we offer a
                  refund for any future work that they may request. <br />
                  <br />
                  WebFix will exclusively engage in business transactions with
                  entities possessing proper HST/GST registration or furnished
                  with valid and compliant invoices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML ***** --> */}
      {/* <footer id="footer"> </footer> */}
      <Footer />
    </div>
  );
};

export default PaymentPolicy;
