import React from "react";
// import "./loader.css"

const Loader = () => {
  return (
    <div>
          <div id="spinner-area">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
            <div class="spinner-txt">Webfix...</div>
          </div>
        </div>
        {/* <!-- ***** FRAME MODE ****** --> */}
        <div class="body-borders" data-border="20">
          <div class="top-border bg-white"></div>
          <div class="right-border bg-white"></div>
          <div class="bottom-border bg-white"></div>
          <div class="left-border bg-white"></div>
        </div>
        <img
    class="w-screen h-screen object-cover"
    src="./assets/img/webfix.png"
    alt="Webfix"
  />
        </div>
  );
};

export default Loader;
