import React from "react";

const Topbar = () => {
  return (
    <div class="sec-bg3 infonews" style={{ backgroundColor: "#ebeef5" }}>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 col-md-7 news">
            <span class="badge bg-purple me-2">Offer</span>
            <span>Get Your Website From $222</span>
            <span>
              {" "}
              <a
                href="https://webfix.com/billing/cart.php?a=confproduct&i=0"
                target="_blank"
                rel="noreferrer"
                class="golink"
                title="Getting Started"
              >
                Getting Started <i class="fas fa-arrow-circle-right"></i>
              </a>
            </span>
          </div>
          <div class="col-6 col-md-5 link">
            <div class="infonews-nav float-end">
              <a
                href="https://webfix.com/billing/cart.php?a=view"
                class="iconews"
                title="Cart"
              >
                <i class="ico-shopping-cart f-18 w-icon"></i>
              </a>
              <a
                href="tel:14168888130"
                class="iconews tabphone"
                title="Phone Number"
              >
                📞(416) 888-8130
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
