import React from "react";

const Footer = () => {
  return (
    <footer class="footer">
      <div class="container">
        <div class="footer-top">
          <div class="row">
            <div class="col-sm-6 col-md-3">
              <div class="heading">Company</div>
              <ul class="footer-menu">
                <li class="menu-item">
                  <a href="about" title="About">
                    About
                  </a>
                </li>
                <li class="menu-item">
                  <a href="process" title="Process">
                    Process
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://www.linkedin.com/jobs/webfix-jobs-worldwide"
                    title="Careers"
                  >
                    Careers
                  </a>
                </li>
                <li class="menu-item">
                  <a href="privacypolicy" title="Privacy Policy">
                    Privacy Policy
                  </a>
                </li>
                <li class="menu-item">
                  <a href="termsofuse" title="Terms of Use">
                    Terms of Use
                  </a>
                </li>
                <li class="menu-item">
                  <a href="paymentpolicy" title="Payment Policy">
                    Payment Policy
                  </a>
                </li>
                <li class="menu-item">
                  <a href="companypolicy" title="Company Policy">
                    Company Policy
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="heading">Services</div>
              <ul class="footer-menu">
                <li class="menu-item">
                  <a
                    href="https://webfix.com/billing/index.php?rp=/store/logo-designing"
                    title="Design"
                  >
                    Design
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://webfix.com/billing/index.php?rp=/store/website-development"
                    title="Development"
                  >
                    Development
                  </a>
                </li>
                <li class="menu-item">
                  <a href="https://webfix.com/ssm" title="Digital Marketing">
                    Digital Marketing
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://webfix.com/billing/cart.php?a=add&domain=register"
                    title="Domain Registration"
                  >
                    Domain Registration
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://webfix.com/billing/index.php?rp=/store/hosting"
                    title="Hosting Server"
                  >
                    Hosting Server
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://webfix.com/billing/index.php?rp=/store/ssl-certificates"
                    title="Security"
                  >
                    Security
                  </a>
                </li>
                <li class="menu-item">
                  <a href="https://webfix.com/blog" title="Blog">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-md-3">
              <div class="heading">Hire an Expert</div>
              <ul class="footer-menu">
                <li class="menu-item">
                  <a
                    href="https://calendly.com/webfix-com/sali"
                    target="_blank"
                    title="IT Consultant"
                    rel="noreferrer"
                  >
                    IT Consultant
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://calendly.com/webfix-com/sali"
                    target="_blank"
                    title="Marketing Consultant"
                    rel="noreferrer"
                  >
                    Marketing Consultant
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://webfix.com/live-website-fixing"
                    target="_blank"
                    title="Live Website Fixing"
                    rel="noreferrer"
                  >
                    Live Website Fixing
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://api.leadconnectorhq.com/widget/form/RfiwGTZCgWXcBUbbq5zl"
                    target="_blank"
                    title="Data Entry Resource"
                    rel="noreferrer"
                  >
                    Data Entry Resource
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://api.leadconnectorhq.com/widget/form/F90uxj6kOQ9JnvEhIAv8"
                    target="_blank"
                    title="Product Photograph"
                    rel="noreferrer"
                  >
                    Product Photography
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://api.leadconnectorhq.com/widget/form/F1Ils3Uj6t9fMrDLgF9p"
                    target="_blank"
                    title="Payment Solutions"
                    rel="noreferrer"
                  >
                    Payment Solutions
                  </a>
                </li>
                <li class="menu-item">
                  <a
                    href="https://webfix.com/billing/index.php?rp=/store/website-support"
                    title="Support Plans"
                  >
                    Support Plans
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-sm-6 col-md-3">
              <img
                class="svg logo-footer d-block"
                src="./assets/img/logo.svg"
                alt="logo"
                width="200"
                height="50"
              />
              <img
                class="svg logo-footer d-none"
                src="./assets/img/logo-light.svg"
                alt="logo"
                width="200"
                height="50"
              />
              <div class="copyright">
                © Copyright 2002 - 2029 | Webfix, Inc. All rights reserved.
              </div>
              <div class="soc-icons">
                <a
                  href="https://www.linkedin.com/company/webfixinc"
                  target="_blank"
                  title="Linkedin"
                  rel="noreferrer"
                >
                  <img src="assets/img/footer/linkedin.svg" alt="" />
                </a>
                <a
                  href="https://www.facebook.com/webfixinc"
                  target="_blank"
                  title="Facebook"
                  rel="noreferrer"
                >
                  <img src="assets/img/footer/facebook.svg" alt="" />
                </a>
                <a
                  href="https://www.youtube.com/@webfixinc"
                  target="_blank"
                  title="Youtube"
                  rel="noreferrer"
                >
                  <img src="assets/img/footer/youtube.svg" alt="" />
                </a>
                <a
                  href="https://www.instagram.com/webfixinc"
                  target="_blank"
                  title="Instagram"
                  rel="noreferrer"
                >
                  <img src="assets/img/footer/inst.svg" alt="" />
                </a>
                <a
                  href="https://www.twitter.com/webfixinc"
                  target="_blank"
                  title="Twitter"
                  rel="noreferrer"
                >
                  <img src="assets/img/footer/twitter.svg" alt="" />
                </a>
              </div>
              <div class="payment__box">
                <p>Payments We Accept</p>
                <ul class="payment-list">
                  <li>
                    <i class="fab fa-cc-paypal"></i>
                  </li>
                  <li>
                    <i class="fab fa-cc-visa"></i>
                  </li>
                  <li>
                    <i class="fab fa-cc-mastercard"></i>
                  </li>
                  <li>
                    <i class="fab fa-cc-apple-pay"></i>
                  </li>
                  <li>
                    <i class="fab fa-cc-discover"></i>
                  </li>
                  <li>
                    <i class="fab fa-cc-amazon-pay"></i>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12">
            <div class="footer__bottom">
              <div class="footer__text d-flex justify-content-between align-items-center">
                <h5>
                  The Webfix wordmark is a registered trademark of Webfix, Inc.
                </h5>
                <h5>Copyright © 2002 - 2029 WebFix. All rights reserved</h5>
                <h5>
                  Check out our{" "}
                  <a
                    href="https://g.page/Webfix?gm"
                    target="_blank"
                    style={{ color: "#ED2024" }}
                    rel="noreferrer"
                  >
                    ★★★★★
                  </a>{" "}
                  reviews on google.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
