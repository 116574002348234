import React from "react";
import { useNavigate } from "react-router-dom";
import './Error.css'
const Error = ({ errorMessage = "Something went wrong!" }) => {
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <div className="error-container">
        <h1 className="error-title">Oops!</h1>
        <p className="error-message">{errorMessage}</p>
        <button className="error-button" onClick={() => navigate("/")}>
          Go Back to Home
        </button>
      </div>
    </div>
  );
};

export default Error;
