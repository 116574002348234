import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import "./chart.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register necessary Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

function Result() {
  const location = useLocation();
  const metrics = location.state?.metrics; // Retrieve metrics passed from navigation

  useEffect(() => {
    // Dynamically load the Calendly script only when needed
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    script.onload = () => {
      window.Calendly = window.Calendly || {}; // Ensure Calendly is available globally
    };
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script); // Clean up on unmount
    };
  }, []);

  const handleCalendlyClick = (e) => {
    e.preventDefault();
    // Check if Calendly is defined and initialized
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/webfix-com/talk-with-expert?hide_gdpr_banner=1&primary_color=ed2024&month=2024-07'
      });
    }
    return false;
  };

  if (!metrics) {
    return <p className="text-red-500 text-center">No metrics available. Please go back and submit a URL.</p>;
  }

  // Data for the circular chart
  const chartData = {
    labels: [
      `Performance: ${metrics.performance}`,
      `First Contentful Paint: ${metrics.firstContentfulPaint}`, 
      `Speed Index: ${metrics.speedIndex}`,
      `Largest Contentful Paint: ${metrics.largestContentfulPaint}`,
      `Cumulative Layout Shift: ${metrics.cumulativeLayoutShift}`,
      `Total Blocking Time: ${metrics.totalBlockingTime}`,
    ],
    datasets: [
      {
        data: [
          metrics.performance,
          parseFloat(metrics.firstContentfulPaint),
          parseFloat(metrics.speedIndex),
          parseFloat(metrics.largestContentfulPaint),
          parseFloat(metrics.cumulativeLayoutShift),
          parseFloat(metrics.totalBlockingTime),
        ],
        backgroundColor: [
          "#34D399", // Green
          "#3B82F6", // Blue
          "#FBBF24", // Yellow
          "#FB7185", // Red
          "#A78BFA", // Purple
          "#F87171", // Light Red
        ],
        hoverOffset: 4,
      },
    ],
  };

  return (
    <div>
      <Navbar />
      <div className="min-h-screen flex flex-col items-center justify-center mt-14 py-16 p-6">
        <div className="flex flex-col items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-700 mb-4">Performance Metrics</h1>
          
          <button
            className="w-fit bg-slate-900 transition-all hover:translate-y-1 hover:bg-red-600 text-white font-bold py-2 px-6 cursor-pointer"
            onClick={handleCalendlyClick}
          >
            <span className="block text-center">Talk to an Expert</span>
          </button>
        </div>

        <div className="w-full m-auto sm:w-[75%] md:w-[55%] h-auto sm:h-[40%] md:h-[30%] bg-white p-6 shadow-lg rounded-lg">
  <Doughnut data={chartData} className="w-full" />
</div>

      </div>
      
      <br />
        <br />
      <Footer className = "bg-white"/>
    </div>
  );
}

export default Result;
