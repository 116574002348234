import React from "react";

import "./fixIt.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const FixIt = () => {
  return (
    <div class="box-container limit-width">
      {/* <!-- ***** SETTINGS ****** --> */}
      <section id="settings"> </section>
      {/* <!-- ***** LOADING PAGE ****** --> */}
      <div id="spinner-area">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
          <div class="spinner-txt">Webfix...</div>
        </div>
      </div>
      {/* <!-- ***** FRAME MODE ****** --> */}
      <div class="body-borders" data-border="20">
        <div class="top-border bg-white"></div>
        <div class="right-border bg-white"></div>
        <div class="bottom-border bg-white"></div>
        <div class="left-border bg-white"></div>
      </div>
      {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
      {/* <header id="header"> </header> */}
      <Navbar />

      {/* <!-- ***** YOUR CONTENT ***** --> */}

      {/* <!-- live-website-fixing.html --> */}
      <form
        id="webFixForm"
        class="webfix-form"
        action="send-email.php"
        method="POST"
      >
        <div class="form-group">
          <label for="websiteUrl" class="form-label">
            Website URL:
          </label>
          <input
            type="text"
            id="websiteUrl"
            name="websiteUrl"
            class="form-input"
            placeholder="https://"
            required
          />
        </div>

        <div class="form-group">
          <label for="platform" class="form-label">
            Select Platform:
          </label>
          <select id="platform" name="platform" class="form-select" required>
            <option value="" disabled selected>
              Select a platform
            </option>
            <option value="Shopify">Shopify</option>
            <option value="WooCommerce">WooCommerce</option>
            <option value="Wix">Wix</option>
            <option value="Squarespace">Squarespace</option>
            <option value="HTML/CSS/Java">HTML/CSS/Java</option>
            <option value="Laravel (PHP)">Laravel (PHP)</option>
            <option value="Django (Python)">Django (Python)</option>
            <option value="Others">Others</option>
          </select>
        </div>

        <div class="form-group">
          <label for="email" class="form-label">
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            class="form-input"
            placeholder="you@example.com"
            required
          />
        </div>

        <button type="submit" class="form-button">
          Submit
        </button>
      </form>

      {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML ***** --> */}
      {/* <footer id="footer"> </footer> */}
      <Footer />
    </div>
  );
};

export default FixIt;
