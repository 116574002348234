import React from "react";

import "./portfolio.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const Portfolio = () => {
  return (
    <div class="box-container limit-width">
      {/* <!-- ***** SETTINGS ****** --> */}
      <section id="settings"> </section>
      {/* <!-- ***** LOADING PAGE ****** --> */}
      <div id="spinner-area">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
          <div class="spinner-txt">Webfix...</div>
        </div>
      </div>
      {/* <!-- ***** FRAME MODE ****** --> */}
      <div class="body-borders" data-border="20">
        <div class="top-border bg-white"></div>
        <div class="right-border bg-white"></div>
        <div class="bottom-border bg-white"></div>
        <div class="left-border bg-white"></div>
      </div>
      {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
      {/* <header id="header"> </header> */}
      <Navbar />

      {/* <!-- Hero Section --> */}
      <section class="hero">
        <div class="hero-content">
          <h1>Welcome to My Portfolio</h1>
          <p>Explore my work, creativity, and design solutions.</p>
        </div>
      </section>

      {/* <!-- Project Section 1 --> */}
      <section class="project-section">
        <div class="text-container">
          <h2>Project Title 1</h2>
          <p>
            Brief description of Project 1, outlining key details and what makes
            it special.
          </p>
        </div>
        <div class="image-container">
          <img src="./p1.png" alt="Project 1" />
        </div>
      </section>

      {/* <!-- Project Section 2 --> */}
      <section class="project-section">
        <div class="image-container">
          <img src="./project2.jpg" alt="Project 2" />
        </div>
        <div class="text-container">
          <h2>Project Title 2</h2>
          <p>
            Brief description of Project 2, outlining key details and what makes
            it special.
          </p>
        </div>
      </section>

      {/* <!-- Additional sections can be added similarly --> */}
      {/* <footer id="footer"></footer> */}
      <Footer />
    </div>
  );
};

export default Portfolio;
