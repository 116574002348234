import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import MetricsDisplay from "../../components/displayMatrix/MetricsDisplay";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import Error from "../../components/error/Error";

const Home = () => {
  const [currentSlider, setCurrentSlide] = useState(0);
  const [swiperSlider, setswiperSlide] = useState(0);
  const [url, setUrl] = useState("");
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentSlider === 4) {
        setCurrentSlide(0);
      } else {
        setCurrentSlide((prev) => prev + 1);
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentSlider]);



  useEffect(() => {
    const intervsl = setTimeout(() => {
      setLoading(false); 
    }, 1000); 

    return () => {
      clearTimeout(intervsl)
    }
  }, []);

  useEffect(() => {
    const swiperId = setInterval(() => {
      if (swiperSlider === 6) {
        setswiperSlide(0);
      } else {
        setswiperSlide((prev) => prev + 1);
      }
    }, 2000);

    return () => {
      clearInterval(swiperId);
    };
  }, [swiperSlider]);


  const handleUrlSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const apiCall = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?url=${encodeURIComponent(
        url
      )}`;

      const response = await axios.get(apiCall);
      const audits = response.data.lighthouseResult.audits;

      // Extract relevant metrics
      const fetchedMetrics = {
        performance: Math.round(
          response.data.lighthouseResult.categories.performance.score * 100
        ),
        firstContentfulPaint: audits["first-contentful-paint"]?.displayValue,
        speedIndex: audits["speed-index"]?.displayValue,
        largestContentfulPaint:
          audits["largest-contentful-paint"]?.displayValue,
        cumulativeLayoutShift: audits["cumulative-layout-shift"]?.displayValue,
        totalBlockingTime: audits["total-blocking-time"]?.displayValue,
      };

      setMetrics(fetchedMetrics);

      // Navigate to the chart page and pass metrics as state
      navigate("/result", { state: { metrics: fetchedMetrics } });
    } catch (err) {
      setError(true)
      navigate("/error");

      // setError("Failed to fetch metrics. Please check the URL and try again.");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    // Dynamically load the Calendly script only when needed
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    script.onload = () => {
      window.Calendly = window.Calendly || {}; // Ensure Calendly is available globally
    };
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script); // Clean up on unmount
    };
  }, []);

  const handleCalendlyClick = (e) => {
    e.preventDefault();
    // Check if Calendly is defined and initialized
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/webfix-com/talk-with-expert?hide_gdpr_banner=1&primary_color=ed2024&month=2024-07'
      });
    }
    return false;
  };



  return (
    <>
      <div>{metrics && <MetricsDisplay metrics={metrics} />}</div>
    
  {/* Display loader when loading is true */}


  <>
      {loading ? (
        <Loader /> // Use the Loader component here
      ) : (
        <div>{metrics && <MetricsDisplay metrics={metrics} />}
        
        <Navbar />
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-TT6T3M2P"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="hello"
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
      <div class="box-container limit-width">
        {/* <!-- ***** SETTINGS ****** --> */}
        <section id="settings"></section>
     
   
        {/* <!-- ***** FRAME MODE ****** --> */}
        <div class="body-borders" data-border="20">
          <div class="top-border bg-white"></div>
          <div class="right-border bg-white"></div>
          <div class="bottom-border bg-white"></div>
          <div class="left-border bg-white"></div>
        </div>
        {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
        <header id="header"></header>

        {/* <!-- let-area-stat --> */}
        <section class="let" id="let-area">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="let__content">
                  <h1>We Build, Fix & Rank Websites</h1>
                  <p>
                    We Build, Revamp & Fix Websites Since 2002. <br />
                    Trust us for your business needs, with a 99.9% satisfaction
                    guarantee.
                  </p>
                  <form class="let__input" onSubmit={handleUrlSubmit}>
                    <input
                      type="url"
                      placeholder="https://"
                      required
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                    />
                    <button type="submit" disabled={loading}>
                      {/* <a
                        href="/"
                        onclick="Calendly.initPopupWidget({url:'https://calendly.com/webfix-com/fix-it'});return false;"
                        style={{ color: "white" }}
                      >
                      </a> */}
                      {loading ? "Analyzing..." : "FIX IT"}
                    </button>
                    {/* <!-- Calendly badge widget begin --> */}
                  </form>
                  <div className="mt-3">
                    {error && <p className="error">{error}</p>}
                  </div>
                  <div class="info-inline-center">
                    <span class="tick">✔</span>Pay per minute, not hourly.
                    <span class="tick">✔</span>90% of websites are fixed in
                    under 28 minutes. <span class="tick">✔</span>Watch live
                    streaming while we fix your website.
                  </div>
                </div>

                <div class="let__flex">
                  <div class="left__left">
                    <div class="single__let position-relative">
                      <a href="/">
                        <img
                          src="assets/img/index-1/s1.png"
                          alt="Website Repair Canada"
                        />
                      </a>
                      <div class="let__text curve d-flex align-items-end justify-content-between">
                        <a href="redesign-website">
                          <div class="let__text-heading">
                            <h3>Redesign Website</h3>
                            <span>Starting From $29/h</span>
                          </div>
                          <div class="let__arrow curve">
                            <img
                              src="assets/img/index-1/curve-right.svg"
                              alt="CMS Development North America"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="let__right">
                    <div class="single__let position-relative">
                      <a href="/">
                        <img
                          src="assets/img/index-1/s2.png"
                          alt="Website Revamp Services Canada"
                        />
                      </a>
                      <div class="let__text bottom d-flex align-items-end justify-content-between">
                        <a href="https://webfix.com/billing/index.php?rp=/store/website-development">
                          <div class="let__text-heading">
                            <h3>
                              Create
                              <br />
                              New website
                            </h3>
                            <span>Starting From $222</span>
                          </div>
                          <div class="let__arrow straigt-top">
                            <img
                              src="assets/img/index-1/straigt-right.svg"
                              alt="Affordable Website Development Canada"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="single__let position-relative">
                      <a href="/">
                        <img
                          src="assets/img/index-1/s3.png"
                          alt="WordPress Hosting Canada"
                        />
                      </a>
                      <div class="let__text bottom d-flex align-items-end justify-content-between">
                        <a href="https://webfix.com/billing/index.php?rp=/store/hosting/wordpress-hosting">
                          <div class="let__text-heading">
                            <h3>
                              WordPress
                              <br />
                              Hosting
                            </h3>
                            <span>Starting From $5.99</span>
                          </div>
                          <div class="let__arrow straigt-bottom">
                            <img
                              src="assets/img/index-1/straigt-right.svg"
                              alt="Pay-Per-Minute Website Fix"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- let-area-end --> */}

        {/* <!-- our-area-start --> */}
        <section
          id="our-area"
          class="our"
          style={{ backgroundImage: `url('assets/img/index-1/bg-img.png')` }}
        >
          <div class="our__title">
            <h3>Our Recent Projects</h3>
            <span>SOFTWARES • CMS • UX/UI • iOS• ANDROID</span>
          </div>
          <div class="our__slider">
            <div class="swiper mySwipers">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  style={{
                    transform: `translateX(-${swiperSlider * 390}px)`,
                    transition: "transform 1s ease-in-out",
                  }}
                >
                  <img
                    src="assets/img/index-1/portfolio1.jpg"
                    alt="UX/UI Design Services Canada"
                  />
                </div>
                <div
                  class="swiper-slide"
                  style={{
                    transform: `translateX(-${swiperSlider * 390}px)`,
                    transition: "transform 1s ease-in-out",
                  }}
                >
                  <img
                    src="assets/img/index-1/portfolio2.jpg"
                    alt="E-commerce Website Maintenance Canada"
                  />
                </div>
                <div
                  class="swiper-slide"
                  style={{
                    transform: `translateX(-${swiperSlider * 390}px)`,
                    transition: "transform 1s ease-in-out",
                  }}
                >
                  <img
                    src="assets/img/index-1/portfolio3.jpg"
                    alt="Mobile-Friendly Web Solutions Canada"
                  />
                </div>
                <div
                  class="swiper-slide"
                  style={{
                    transform: `translateX(-${swiperSlider * 390}px)`,
                    transition: "transform 1s ease-in-out",
                  }}
                >
                  <img
                    src="assets/img/index-1/portfolio4.jpg"
                    alt="24/7 Website Support Canada"
                  />
                </div>
                <div
                  class="swiper-slide"
                  style={{
                    transform: `translateX(-${swiperSlider * 390}px)`,
                    transition: "transform 1s ease-in-out",
                  }}
                >
                  <img
                    src="assets/img/index-1/portfolio1.jpg"
                    alt="SEO services in north America"
                  />
                </div>
                <div
                  class="swiper-slide"
                  style={{
                    transform: `translateX(-${swiperSlider * 390}px)`,
                    transition: "transform 1s ease-in-out",
                  }}
                >
                  <img
                    src="assets/img/index-1/portfolio2.jpg"
                    alt="SEO Services North America"
                  />
                </div>
                <div
                  class="swiper-slide"
                  style={{
                    transform: `translateX(-${swiperSlider * 390}px)`,
                    transition: "transform 1s ease-in-out",
                  }}
                >
                  <img
                    src="assets/img/index-1/portfolio3.jpg"
                    alt="Local SEO North America"
                  />
                </div>
                <div
                  class="swiper-slide"
                  style={{
                    transform: `translateX(-${swiperSlider * 390}px)`,
                    transition: "transform 1s ease-in-out",
                  }}
                >
                  <img
                    src="assets/img/index-1/portfolio4.jpg"
                    alt="Search Engine Optimization USA"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="ready__btn">
            <a
              href="portfolio"
              style={{
                display: "block !important",
                margin: "auto !important",
                padding: "15px 5px !important",
              }}
            >
              See Portfolio
            </a>
          </div>
        </section>
        {/* <!-- our-area-end --> */}

        {/* <!-- ***** MAP ***** --> */}
        <section
          id="service-area"
          class="services maping sec-normal sec-grad-grey-to-grey bg-colorstyle pb-5"
        >
          <div class="container">
            <div class="service-wrap">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <h2 class="section-heading text-white mergecolor">
                    Meet People From All Seven Continents
                  </h2>
                  <p class="section-subheading text-light mergecolor">
                    <span class="golink">Who Have Chosen </span> Webfix.com
                    <span class="c-pink">Since 2002 & They Are </span> Fully
                    Satisfied.
                  </p>
                </div>
                <div class="col-md-12 pt-5 position-relative">
                  <img
                    class="map__img"
                    src="assets/patterns/map.svg"
                    alt="Search Engine Optimization USA"
                  />
                  <div class="lazyloads">
                    {/* <!-- <img src="assets/patterns/map.svg" class="w-10" alt="Load Balancing">--> */}
                    <div class="single__video f1">
                      <img
                        src="assets/img/index-1/high/flag3.webp"
                        alt="Best SEO Agency North America"
                        class="flag1"
                      />
                      <a
                        class="my-video-links"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/QQQgAjxCOOs?si=dgTPvYNqgBZxo2fP"
                      >
                        <img
                          src="assets/img/index-1/ca.png"
                          alt="WordPress Hosting Canada"
                        />
                      </a>
                    </div>
                    <div class="single__video f2">
                      <img
                        src="assets/img/index-1/high/flag1.webp"
                        alt="SEO Experts USA"
                        class="flag1"
                      />
                      <a
                        class="my-video-links"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                      >
                        <img
                          src="assets/img/index-1/paly.png"
                          alt="WordPress Hosting Canada"
                        />
                      </a>
                    </div>
                    <div class="single__video f3">
                      <img
                        src="assets/img/index-1/flag8.png"
                        alt="Digital Marketing North America"
                        class="flag1"
                      />
                      <a
                        class="my-video-links"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                      >
                        <img src="assets/img/index-1/paly.png" alt="" />
                      </a>
                    </div>
                    <div class="single__video f4">
                      <img
                        src="assets/img/index-1/high/flag5.png"
                        alt="Organic SEO Services North America"
                        class="flag1"
                      />
                      <a
                        class="my-video-links"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                      >
                        <img
                          src="assets/img/index-1/paly.png"
                          alt="UX/UI Design Services Canada"
                        />
                      </a>
                    </div>
                    <div class="single__video f5">
                      <img
                        src="assets/img/index-1/high/flag2.png"
                        alt="E-commerce SEO North America"
                        class="flag1"
                      />
                      <a
                        class="my-video-links"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                      >
                        <img
                          src="assets/img/index-1/paly.png"
                          alt="Organic SEO Services North America"
                        />
                      </a>
                    </div>
                    <div class="single__video f6">
                      <img
                        src="assets/img/index-1/high/flag4.webp"
                        alt="SEO Consultation North America"
                        class="flag1"
                      />
                      <a
                        class="my-video-links"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                      >
                        <img
                          src="assets/img/index-1/paly.png"
                          alt="SEO Services North America"
                        />
                      </a>
                    </div>
                    <div class="single__video f7">
                      <img
                        src="assets/img/index-1/high/flag7.svg"
                        alt="Mobile-Friendly Web Solutions Canada"
                        class="flag1"
                      />
                      <a
                        class="my-video-links"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                      >
                        <img
                          src="assets/img/index-1/paly.png"
                          alt="24/7 Website Support Canada"
                        />
                      </a>
                    </div>
                    <div class="single__video f8">
                      <img
                        src="assets/img/index-1/high/flag6.svg"
                        alt="SEO Experts USA"
                        class="flag1"
                      />
                      <a
                        class="my-video-links"
                        data-autoplay="true"
                        data-vbtype="video"
                        href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                      >
                        <img
                          src="assets/img/index-1/paly.png"
                          alt="Website Repair Canada"
                        />
                      </a>
                    </div>
                    <div class="more__btn">
                      <img
                        src="assets/img/index-1/arrow.png"
                        alt="E-commerce Website Maintenance Canada"
                      />
                      <a href="https://youtube.com/playlist?list=PLuhBP65_rIN-8vu1ka4PzvMmyl8o5prUk&si=-yNK_aFbX3NuCxPl">
                        More <br />
                        Videos
                      </a>
                    </div>
                  </div>
                  <span
                    class="datacenters montreal"
                    data-bs-toggle="popover"
                    data-bs-container="body"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Montreal"
                    data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                    role="button"
                    aria-label="Montreal"
                  ></span>
                  <span
                    class="datacenters newyork"
                    data-bs-toggle="popover"
                    data-bs-container="body"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="New York"
                    data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                    role="button"
                    aria-label="New York"
                  ></span>
                  <span
                    class="datacenters portugal"
                    data-bs-toggle="popover"
                    data-bs-container="body"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Portugal"
                    data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                    role="button"
                    aria-label="Portugal"
                  ></span>
                  <span
                    class="datacenters london"
                    data-bs-toggle="popover"
                    data-bs-container="body"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="London"
                    data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                    role="button"
                    aria-label="London"
                  ></span>
                  <span
                    class="datacenters moskow"
                    data-bs-toggle="popover"
                    data-bs-container="body"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Moskow"
                    data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                    role="button"
                    aria-label="Moskow"
                  ></span>
                  <span
                    class="datacenters hongkong"
                    data-bs-toggle="popover"
                    data-bs-container="body"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Hong Kong"
                    data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                    role="button"
                    aria-label="Hong Kong"
                  ></span>
                  <span
                    class="datacenters singapure"
                    data-bs-toggle="popover"
                    data-bs-container="body"
                    data-bs-trigger="hover"
                    data-bs-placement="top"
                    title="Singapure"
                    data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                    role="button"
                    aria-label="Singapure"
                  ></span>
                </div>
                {/* <!-- mobile coountry and video box --> */}

                <div
                  id="mobile__map"
                  style={{ backgroundImage: `url('assets/patterns/map.svg')` }}
                >
                  {/* <!--             <img class="mobile_map" src="assets/patterns/map.svg" alt="" />--> */}
                  <div class="container">
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="mobile__wrap">
                          <div
                            class="single__box d-flex justify-content-between align-items-center"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div class="mobile__wrap-left">
                              <img
                                src="assets/img/index-1/high/flag1.webp"
                                alt="Pay-Per-Minute Website Fix"
                              />
                            </div>
                            <div class="mobile__wrap-right">
                              <a
                                class="my-video-links"
                                data-autoplay="true"
                                data-vbtype="video"
                                href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                              >
                                <img
                                  src="assets/img/index-1/paly.png"
                                  alt="SEO services in north America"
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            class="single__box d-flex justify-content-between align-items-center"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div class="mobile__wrap-left">
                              <img
                                src="assets/img/index-1/high/flag2.png"
                                alt="Local SEO North America"
                              />
                            </div>
                            <div class="mobile__wrap-right">
                              <a
                                class="my-video-links"
                                data-autoplay="true"
                                data-vbtype="video"
                                href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                              >
                                <img
                                  src="assets/img/index-1/paly.png"
                                  alt="Mobile-Friendly Web Solutions Canada"
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            class="single__box d-flex justify-content-between align-items-center"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div class="mobile__wrap-left">
                              <img
                                src="assets/img/index-1/high/flag3.webp"
                                alt="24/7 Website Support Canada"
                              />
                            </div>
                            <div class="mobile__wrap-right">
                              <a
                                class="my-video-links"
                                data-autoplay="true"
                                data-vbtype="video"
                                href="https://youtu.be/QQQgAjxCOOs?si=dgTPvYNqgBZxo2fP"
                              >
                                <img
                                  src="assets/img/index-1/ca.png"
                                  alt="Website Repair Canada"
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            class="single__box d-flex justify-content-between align-items-center"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div class="mobile__wrap-left">
                              <img
                                src="assets/img/index-1/high/flag4.webp"
                                alt="Website Repair Canada"
                              />
                            </div>
                            <div class="mobile__wrap-right">
                              <a
                                class="my-video-links"
                                data-autoplay="true"
                                data-vbtype="video"
                                href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                              >
                                <img
                                  src="assets/img/index-1/paly.png"
                                  alt="Website Repair Canada"
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            class="single__box d-flex justify-content-between align-items-center"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div class="mobile__wrap-left">
                              <img
                                src="assets/img/index-1/high/flag5.png"
                                alt="Website Repair Canada"
                              />
                            </div>
                            <div class="mobile__wrap-right">
                              <a
                                class="my-video-links"
                                data-autoplay="true"
                                data-vbtype="video"
                                href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                              >
                                <img
                                  src="assets/img/index-1/paly.png"
                                  alt="Website Repair Canada"
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            class="single__box d-flex justify-content-between align-items-center"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div class="mobile__wrap-left">
                              <img
                                src="assets/img/index-1/high/flag6.svg"
                                alt="Website Repair Canada"
                              />
                            </div>
                            <div class="mobile__wrap-right">
                              <a
                                class="my-video-links"
                                data-autoplay="true"
                                data-vbtype="video"
                                href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                              >
                                <img
                                  src="assets/img/index-1/paly.png"
                                  alt=" Website Revamp Services Canada"
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            class="single__box d-flex justify-content-between align-items-center"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div class="mobile__wrap-left">
                              <img
                                src="assets/img/index-1/high/flag7.svg"
                                alt=" Website Revamp Services Canada"
                              />
                            </div>
                            <div class="mobile__wrap-right">
                              <a
                                class="my-video-links"
                                data-autoplay="true"
                                data-vbtype="video"
                                href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                              >
                                <img
                                  src="assets/img/index-1/paly.png"
                                  alt=" Website Revamp Services Canada"
                                />
                              </a>
                            </div>
                          </div>
                          <div
                            class="single__box d-flex justify-content-between align-items-center"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div class="mobile__wrap-left">
                              <img
                                src="assets/img/index-1/flag8.png"
                                alt=" Website Revamp Services Canada"
                              />
                            </div>
                            <div class="mobile__wrap-right">
                              <a
                                class="my-video-links"
                                data-autoplay="true"
                                data-vbtype="video"
                                href="https://youtu.be/SgTCSgJSd3k?si=a_tSaTTFu3sy7GBl"
                              >
                                <img
                                  src="assets/img/index-1/paly.png"
                                  alt=" Website Revamp Services Canada"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <!-- mobile coountry and video box --> */}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ready-area-start --> */}
        <section class="ready" id="ready">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ready__flex">
                  <img
                    src="assets/img/index-1/laptop.png"
                    alt=" Website Revamp Services Canada"
                  />
                  <div class="our__title">
                    <h3>Ready to get started?</h3>
                    <span>
                      Feel free to contact us to discuss your project
                      requirements. <br />
                      We're here to answer any questions you may have.
                    </span>
                  </div>

                  <button className="w-fit h-16 m-auto flex justify-center items-center bg-slate-900 transition-all hover:translate-y-1 hover:bg-red-600 text-white font-bold py-2 px-6 cursor-pointer " onClick={handleCalendlyClick} >
  <span
    className="block hover:text-white text-xl text-center" // Ensures the text is centered
    >
    Talk to an Expert
  </span>
</button>
                  {/* <div class="ready__btn">
                    <a
                      href="/"
                      onClick="Calendly.initPopupWidget({url:'https://calendly.com/webfix-com/talk-with-expert?hide_gdpr_banner=1&primary_color=ed2024&month=2024-07'});return false;"
                      style={{ color: "white", cursor: "pointer" }}
                    >
                      Talk to an Expert
                    </a>

                    <span>24/7/365 live</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ready-area-end --> */}

        <br />
        <br />
        <br />
        <div class="container">
          <div class="our__title text-center">
            <h3>Who Chose WebFix</h3>
            <span>
              WebFix is the best choice for startups, e-stores, self-employed
              individuals,
              <br />
              and companies without a dedicated IT department.
            </span>
          </div>
          <section
            className="customer-logos slider"
            style={{
              backgroundColor: "#ebeff5",
              padding: "30px",
              display: "flex",
              gap: "32px",
              overflow: "hidden",
            }}
          >
            <div
              className="slide"
              style={{
                transform: `translateX(-${currentSlider * 380}px)`,
                transition: "transform 1s ease-in-out",
              }}
            >
              <img
                src="assets/img/clients/bg.jpg"
                alt="Pay-Per-Minute Website Fix"
              />
            </div>
            <div
              className="slide"
              style={{
                transform: `translateX(-${currentSlider * 380}px)`,
                transition: "transform 1s ease-in-out",
              }}
            >
              <img
                src="assets/img/clients/br.jpg"
                alt="Pay-Per-Minute Website Fix"
              />
            </div>
            <div
              className="slide"
              style={{
                transform: `translateX(-${currentSlider * 380}px)`,
                transition: "transform 1s ease-in-out",
              }}
            >
              <img
                src="assets/img/clients/mm.jpg"
                alt="Pay-Per-Minute Website Fix"
              />
            </div>
            <div
              className="slide"
              style={{
                transform: `translateX(-${currentSlider * 380}px)`,
                transition: "transform 1s ease-in-out",
              }}
            >
              <img
                src="assets/img/clients/kfc.jpg"
                alt="Pay-Per-Minute Website Fix"
              />
            </div>
            <div
              className="slide"
              style={{
                transform: `translateX(-${currentSlider * 380}px)`,
                transition: "transform 1s ease-in-out",
              }}
            >
              <img
                src="assets/img/clients/ddd.jpg"
                alt="Pay-Per-Minute Website Fix"
              />
            </div>
            <div
              className="slide"
              style={{
                transform: `translateX(-${currentSlider * 380}px)`,
                transition: "transform 1s ease-in-out",
              }}
            >
              <img
                src="assets/img/clients/cc.jpg"
                alt="Pay-Per-Minute Website Fix"
              />
            </div>
            <div
              className="slide"
              style={{
                transform: `translateX(-${currentSlider * 380}px)`,
                transition: "transform 1s ease-in-out",
              }}
            >
              <img
                src="assets/img/clients/pp.jpg"
                alt="Pay-Per-Minute Website Fix"
              />
            </div>
          </section>
        </div>
        <br />
        <br />

        <div class="quote-container">
          <div class="quote">
            <p>
              "Our promise is simple: no extra charges, no hidden costs, and pay
              per minute. Watch live streaming as we work on your website,
              ensuring complete transparency."
            </p>
          </div>
          <div class="author-info">
            <img src="assets/img/ceo.jpg" alt="Avatar" class="avatar" />
            <div class="name">S Ali</div>
            <div class="designation">CEO, WebFix</div>
          </div>
        </div>

        {/* <!-- ***** HELP ***** --> */}
        <section
          id="help"
          class="services help sec-bg2 pt-4 pb-80 bg-colorstyle"
        >
          <div class="container">
            <div class="service-wrap">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-seccolorstyle">
                    <div class="help-item" title="Live Chat">
                      <div class="img">
                        <div class="lazyloads">
                          <svg
                            class="svg ico"
                            viewBox="0 0 32 32"
                            width="60"
                            height="60"
                          >
                            <path
                              fill="#5e686c"
                              d="M12.82 9.14a.46.46 0 0 1-.46-.43c-.18-2.55-2.62-4.55-5.54-4.55a.46.46 0 0 1 0-.91c3.4 0 6.24 2.37 6.46 5.4a.46.46 0 0 1-.43.5l-.03-.01z"
                            />
                            <path
                              fill="#5e686c"
                              d="M3.4 14.99a.65.65 0 0 1-.66-.64l-.15-.94A5.53 5.53 0 0 1 .36 9.03c0-3.2 2.9-5.79 6.47-5.79.26 0 .46.21.46.46 0 .26-.2.46-.46.46-3.06 0-5.55 2.18-5.55 4.87 0 1.45.73 2.81 2 3.75.1.06.17.18.19.3l.14.87.87-.37c.1-.05.22-.05.33-.02 1.07.37 2.27.43 3.37.2a.46.46 0 0 1 .2.9c-1.2.25-2.51.2-3.7-.16l-1 .43a.67.67 0 0 1-.27.06z"
                            />
                            <path
                              id="svg-ico"
                              fill="#ee5586"
                              d="M26.22 28.8a.88.88 0 0 1-.43-.13l-2.08-.88c-1.37.44-2.8.67-4.24.67-6.77 0-12.28-4.9-12.28-10.94s5.5-10.94 12.28-10.94c6.77 0 12.28 4.9 12.28 10.94 0 3.24-1.59 6.28-4.37 8.37l-.34 2.14c0 .4-.36.76-.82.76zm-2.49-1.96c.06 0 .13 0 .18.03l2.25.97.35-2.28a.47.47 0 0 1 .18-.3 9.58 9.58 0 0 0 4.14-7.74c0-5.52-5.1-10.01-11.36-10.01S8.1 12 8.1 17.52s5.1 10.02 11.36 10.02a12.78 12.78 0 0 0 4.26-.7z"
                            />
                            <path
                              fill="#5e686c"
                              d="M24.56 14.99H13.83c-.25 0-.45-.2-.45-.46s.2-.45.45-.45h10.73c.26 0 .46.2.46.45s-.2.46-.46.46zm0 3.19H13.83c-.25 0-.45-.2-.45-.45s.2-.46.45-.46h10.73c.26 0 .46.2.46.46s-.2.45-.46.45zm0 3.13H13.83a.45.45 0 0 1-.45-.45c0-.26.2-.46.45-.46h10.73c.26 0 .46.2.46.46 0 .25-.2.45-.46.45z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Live Chat</div>

                        <div class="description seccolor">
                          Connect with our Live Chat for real-time support!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-seccolorstyle">
                    <a
                      href="https://webfix.com/billing/submitticket.php?step=2&deptid=1"
                      class="help-item"
                      title="Contact Us"
                    >
                      <div class="img">
                        <div class="lazyloads">
                          <svg
                            class="svg ico"
                            viewBox="0 0 32 32"
                            width="60"
                            height="60"
                          >
                            <path
                              fill="#5e686c"
                              d="M29.6 31.9H2.4A2 2 0 0 1 .5 30V12c0-1 .9-1.8 1.9-1.8.2 0 .4.2.4.4s-.2.5-.4.5a1 1 0 0 0-1 1V30c0 .6.4 1 1 1h27.2c.6 0 1-.4 1-1V12c0-.5-.4-1-1-1-.2 0-.4-.1-.4-.4s.2-.4.4-.4c1 0 1.9.9 1.9 1.9v18c0 1-.8 1.8-1.9 1.8z"
                            />
                            <path
                              fill="#5e686c"
                              d="M1.7 31.1h-.3a.4.4 0 0 1 0-.6l10-13c.1-.1.4-.2.6 0 .2.1.2.4 0 .6L2 31l-.3.1zm28.7 0L30 31 20 18a.4.4 0 0 1 0-.5c.2-.2.5-.1.7 0l10 13v.5l-.3.1z"
                            />
                            <path
                              id="svg-ico"
                              fill="#ee5586"
                              d="M16 21.1h-.2L2 11a.4.4 0 0 1 0-.6c0-.2.4-.2.6 0L16 20.1l13.4-9.9h.6c.2 0 0 .6-.1.7L16.3 21l-.3.1z"
                            />
                            <path
                              id="svg-ico"
                              fill="#ee5586"
                              d="M29.6 11h-.2L16 1 2.6 11c-.1.1-.4 0-.5-.1v-.6L15.8.3h.5l13.6 10c.2.1.2.4 0 .6 0 .1-.1.2-.3.2z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Send Ticket</div>
                        <div class="description seccolor">
                          Got an issue? Open a ticket for immediate assistance!
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-seccolorstyle">
                    <a
                      href="https://webfix.com/billing/announcements"
                      class="help-item"
                      title="Knowledge List"
                    >
                      <div class="img">
                        <div class="lazyloads">
                          <svg
                            class="svg ico"
                            viewBox="0 0 32 32"
                            width="60"
                            height="60"
                          >
                            <path
                              fill="#5e686c"
                              d="M15.09 30.07c-.22 0-.45-.05-.67-.14-.52-.24-1.98-.84-2.7-.92a48.8 48.8 0 0 0-4.4-.15c-2.64 0-5.18.1-5.2.1a1.9 1.9 0 0 1-1.98-1.91V3.9c0-1.08.88-1.96 1.96-1.96h12.36c1.08 0 1.96.88 1.96 1.96v24.7c0 .87-.55 1.47-1.33 1.47zm-7.77-2.05c1.92 0 3.43.06 4.47.16 1.02.1 2.89.94 2.96.98.11.05.23.07.33.07.44 0 .5-.44.5-.64V3.9c0-.62-.5-1.13-1.12-1.13H2.1c-.62 0-1.12.51-1.12 1.13v23.15c0 .6.47 1.07 1.06 1.07.07 0 2.63-.1 5.28-.1z"
                            />
                            <path
                              id="svg-ico"
                              fill="#ee5586"
                              d="M16.9 30.07c-.26 0-.5-.07-.72-.21a1.5 1.5 0 0 1-.6-1.27h.84c0 .27.08.47.23.57s.36.1.6 0c.07-.04 1.94-.88 2.95-.98 3.16-.32 9.44-.07 9.71-.06a1.05 1.05 0 0 0 1.1-1.07V3.9c0-.62-.5-1.12-1.12-1.12H17.54c-.62 0-1.12.5-1.12 1.12h-.84c0-1.08.88-1.96 1.96-1.96H29.9c1.08 0 1.96.88 1.96 1.96v23.15a1.9 1.9 0 0 1-1.97 1.91c-.07 0-6.5-.25-9.6.05-.73.08-2.2.68-2.7.92-.23.1-.47.14-.68.14z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Announcements</div>
                        <div class="description seccolor">
                          On the other hand, we denounce with righteous.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="container">
          <div class="our__title text-center">
            <h3>See What Our Clients Say About Us!</h3>
            <span>
              WebFix boasts a diverse clientele from around the globe. We offer
              you top-tier <br />
              website expertise and results, but you don't have to take our word
              for it.
            </span>
            <iframe
              class="lc_reviews_widget"
              src="https://services.leadconnectorhq.com/reputation/widgets/review_widget/M5LRc0avm2M1UDWbVDBY"
              frameborder="0"
              scrolling="no"
              style={{ minWidth: "80%", width: "80%" }}
              title="hhh"
            ></iframe>
          </div>
        </div>

        <br />
        <br />
        {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML **** --> */}
        <Footer />
      </div>
        </div>
      )}
      {error && <Error/>}
    </>


   
    </>
  );
};

export default Home;
