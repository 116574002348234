import React from "react";
import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import "./metricsDisplay.css";

// Register components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MetricsDisplay = ({ metrics }) => {
  console.log("metrics", metrics);
  const data = {
    labels: [
      "Performance",
      "First Contentful Paint",
      "Speed Index",
      "Largest Contentful Paint",
      "Cumulative Layout Shift",
      "Total Blocking Time",
    ],
    datasets: [
      {
        label: "Metrics",
        data: [
          metrics.performance || 0,
          parseFloat(metrics.firstContentfulPaint) || 0,
          parseFloat(metrics.speedIndex) || 0,
          parseFloat(metrics.largestContentfulPaint) || 0,
          parseFloat(metrics.cumulativeLayoutShift) || 0,
          parseFloat(metrics.totalBlockingTime) || 0,
        ],
        backgroundColor: [
          "#4CAF50", // Performance
          "#2196F3", // First Contentful Paint
          "#FFC107", // Speed Index
          "#FF5722", // Largest Contentful Paint
          "#9C27B0", // Cumulative Layout Shift
          "#3F51B5", // Total Blocking Time
        ],
      },
    ],
  };

  return (
    <div className="metrics-display">
      <h2>Analysis Results</h2>
      <div className="chart"></div>
      <ul>
        {metrics.performance && (
          <li>
            <strong>Performance:</strong> {metrics.performance}%
          </li>
        )}
        {metrics.firstContentfulPaint && (
          <li>
            <strong>First Contentful Paint:</strong>{" "}
            {metrics.firstContentfulPaint}
          </li>
        )}
        {metrics.speedIndex && (
          <li>
            <strong>Speed Index:</strong> {metrics.speedIndex}
          </li>
        )}
        {metrics.largestContentfulPaint && (
          <li>
            <strong>Largest Contentful Paint:</strong>{" "}
            {metrics.largestContentfulPaint}
          </li>
        )}
        {metrics.cumulativeLayoutShift && (
          <li>
            <strong>Cumulative Layout Shift:</strong>{" "}
            {metrics.cumulativeLayoutShift}
          </li>
        )}
        {metrics.totalBlockingTime && (
          <li>
            <strong>Total Blocking Time:</strong> {metrics.totalBlockingTime}
          </li>
        )}
      </ul>
    </div>
  );
};

export default MetricsDisplay;
