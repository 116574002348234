import React from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";

const Faq = () => {
  return (
    <>
      <div class="box-container limit-width">
        {/* <!-- ***** SETTINGS ****** --> */}
        <section id="settings"></section>
        {/* <!-- ***** LOADING PAGE ****** --> */}
        <div id="spinner-area">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
            <div class="spinner-txt">Antler...</div>
          </div>
        </div>
        {/* <!-- ***** FRAME MODE ****** --> */}
        <div class="body-borders" data-border="20">
          <div class="top-border bg-white"></div>
          <div class="right-border bg-white"></div>
          <div class="bottom-border bg-white"></div>
          <div class="left-border bg-white"></div>
        </div>
        {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
        {/* <header id="header"></header> */}
        <Navbar />
        {/* <!-- ***** BANNER ***** --> */}
        <div class="top-header">
          <div class="total-grad-inverse"></div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="wrapper">
                  <div class="heading mb-0">Frequently asked questions</div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="cd-filter-block checkbox-group mb-0">
                  <label>
                    <a href="/" aria-label="Searching">
                      <i class="fas fa-search"></i>
                    </a>
                  </label>
                  <input type="text" class="input" placeholder="Search.." />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- ***** KNOWLEDGEBASE ***** --> */}
        <section id="gotop" class="blog motpath pb-80 bg-colorstyle">
          <div class="container">
            <div class="row">
              {/* <!-- sidebar --> */}
              <div class="col-md-12 col-lg-4">
                <aside
                  id="sidebar"
                  class="sidebar mt-80 sec-bg1 bg-seccolorstyle noshadow"
                >
                  <div class="menu categories clear">
                    <h4 class="mergecolor">
                      <b>FAQ.</b>
                    </h4>
                    <hr />
                    <div class="heading pt-2">
                      <a
                        href="#gotop"
                        id="showall"
                        class="gocheck active seccolor"
                      >
                        <div class="lazyload">
                          {/* <!-- <svg class="svg me-3" width="32" height="32" viewBox="0 0 32 32"><path fill="#5e686c" d="M14.9 8.36H9a.43.43 0 0 1-.43-.42v-5.9c0-.24.19-.43.42-.43h5.91c.23 0 .42.18.42.42v5.9c0 .24-.18.43-.42.43zm-5.49-.85h5.07V2.45H9.4v5.06z"/><path id="svg-ico" fill="#ee5586" d="M14.9 19.36H9.25a.43.43 0 0 1 0-.85h5.23v-5.07H9.42v2.1a.43.43 0 0 1-.85 0v-2.52c0-.23.19-.43.42-.43h5.9c.24 0 .43.2.43.43v5.92c0 .23-.18.42-.42.42z"/><path fill="#5e686c" d="M14.9 30.36H9a.43.43 0 0 1-.43-.43v-5.9c0-.24.19-.43.42-.43h5.91c.23 0 .42.19.42.42v5.91c0 .24-.18.43-.42.43zm-5.49-.85h5.07v-5.06H9.4v5.06zM31.14 3.67h-13.5a.43.43 0 0 1 0-.84h13.5c.23 0 .43.18.43.42s-.2.42-.43.42zM27.8 6.29H17.67a.43.43 0 0 1 0-.85h10.14c.23 0 .42.18.42.42s-.2.43-.43.43z"/><path id="svg-ico" fill="#ee5586" d="M31.14 14.91h-13.5a.43.43 0 0 1 0-.85h13.5c.23 0 .43.19.43.43s-.2.42-.43.42zm-3.34 2.61H17.67a.43.43 0 0 1 0-.85h10.14c.23 0 .42.2.42.43s-.2.42-.43.42z"/><path fill="#5e686c" d="M31.14 26.16h-13.5a.43.43 0 0 1 0-.85h13.5c.23 0 .43.18.43.42s-.2.43-.43.43zm-3.38 2.6H17.64a.43.43 0 0 1 0-.85h10.13a.42.42 0 1 1-.01.85zM7.03 25.05H7a.42.42 0 0 1-.38-.33l-1.04-4.76-4.76-1.04a.42.42 0 0 1-.06-.8l9.62-3.5c.15-.06.32-.03.44.1s.16.28.1.44l-3.5 9.62a.44.44 0 0 1-.4.27zM2.4 18.4l3.6.79c.17.04.29.16.33.32l.79 3.6 2.68-7.4-7.4 2.7z"/></svg> --> */}
                        </div>
                        All Answers
                      </a>
                    </div>
                    <div class="heading pt-2">
                      <a
                        href="#gotop"
                        class="gocheck showSingle seccolor"
                        target="1"
                      >
                        <div class="lazyload">
                          {/* <!-- <svg class="svg me-3" width="32" height="32" viewBox="0 0 32 32"><path id="svg-ico" d="M21.294 1.969h-.232c-5.325.087-10.656 3.756-10.656 10.688 0 .238.194.425.425.425.238 0 .425-.194.425-.425 0-6.719 5.125-9.8 9.912-9.838 5.387.038 9.762 4.438 9.762 9.838 0 5.425-4.412 9.837-9.838 9.837H7.767c-3.725 0-6.756-3.031-6.756-6.756s3.031-6.756 6.756-6.756c.238 0 .425-.194.425-.425s-.194-.425-.425-.425c-4.2-.006-7.612 3.412-7.612 7.606s3.413 7.613 7.612 7.613h13.331c5.894 0 10.694-4.794 10.694-10.694 0-5.825-4.694-10.581-10.5-10.688z"/><path fill="#5e686c" d="M24.413 28.15h-6.781c-.156-.6-.631-1.075-1.231-1.231v-2.194c0-.238-.194-.425-.425-.425-.238 0-.425.194-.425.425v2.194c-.6.156-1.075.631-1.231 1.231H7.539c-.237 0-.425.194-.425.425s.194.425.425.425h6.781c.188.738.863 1.281 1.656 1.281s1.469-.544 1.656-1.281h6.781c.238 0 .425-.194.425-.425s-.188-.425-.425-.425zm-8.438 1.281c-.469 0-.856-.381-.856-.856 0-.469.381-.856.856-.856s.856.381.856.856-.387.856-.856.856zM21.238 6.931c.238 0 .425-.194.425-.431-.006-.237-.219-.444-.431-.425-3.381.044-6.375 2.719-6.675 6.044l.006.075c.006.156.012.313.012.463 0 .238.194.425.425.425.238 0 .425-.194.425-.425 0-.162-.006-.331-.012-.525l-.012-.019c.269-2.819 2.888-5.144 5.838-5.181z"/></svg> --> */}
                        </div>
                        Shared Hosting
                      </a>
                    </div>
                    <div class="heading pt-2">
                      <a
                        href="#gotop"
                        class="gocheck showSingle seccolor"
                        target="2"
                      >
                        <div class="lazyload">
                          {/* <!-- <svg class="svg me-3" width="32" height="32" viewBox="0 0 32 32"><path fill="#5e686b" d="M12.7 8.469h1.669a.42.42 0 0 0 0-.838H12.7a.42.42 0 0 0 0 .838zm4.994 0h1.669a.42.42 0 0 0 0-.838h-1.669a.42.42 0 0 0 0 .838z"/><path fill="#5e686b" d="M16.031 14.363c3.906 0 7.087-3.181 7.087-7.088a7.083 7.083 0 0 0-2.431-5.338.415.415 0 0 0-.188-.156A7.052 7.052 0 0 0 16.03.187c-3.906 0-7.088 3.181-7.088 7.088s3.181 7.088 7.088 7.088zm0-13.344c1.219 0 2.356.35 3.313.956l-8.675 2.081a6.254 6.254 0 0 1 5.363-3.038zm-5.837 4.012 10.012-2.406a6.232 6.232 0 0 1 2.075 4.65c0 3.45-2.806 6.25-6.25 6.25a6.256 6.256 0 0 1-5.838-8.494zm-2.288 17.1h16.256a.42.42 0 0 0 .419-.419v-2.956a3.376 3.376 0 0 0-3.375-3.375H10.862a3.376 3.376 0 0 0-3.375 3.375v2.962c0 .231.188.413.419.413zm.413-3.375c0-1.4 1.137-2.544 2.544-2.544h4.75v2.919a.42.42 0 0 0 .838 0v-2.919h4.75c1.4 0 2.544 1.137 2.544 2.544V21.3H8.32v-2.544z"/><path id="svg-ico" fill="#ee5586" d="m7.981 23.825-4.944 4.944a1.69 1.69 0 0 0-.844-.231c-.919 0-1.669.75-1.669 1.669s.75 1.669 1.669 1.669 1.669-.75 1.669-1.669c0-.306-.087-.6-.231-.844l4.944-4.944a.415.415 0 0 0 0-.587.417.417 0 0 0-.594-.006zm-5.787 7.219a.835.835 0 0 1 0-1.668.83.83 0 0 1 .831.831.836.836 0 0 1-.831.837zm27.6-2.506a1.66 1.66 0 0 0-.863.238l-5.056-4.95a.414.414 0 1 0-.581.593l5.044 4.944a1.63 1.63 0 0 0-.231.844c0 .919.756 1.669 1.694 1.669.931 0 1.694-.75 1.694-1.669a1.695 1.695 0 0 0-1.7-1.669zm0 2.506a.845.845 0 0 1-.856-.837c0-.456.387-.831.856-.831.475 0 .863.375.863.831a.86.86 0 0 1-.863.837zM16.45 28.6v-4.656a.42.42 0 0 0-.838 0v4.65a1.675 1.675 0 0 0-1.269 1.619c0 .919.75 1.669 1.669 1.669s1.669-.75 1.669-1.669c0-.775-.525-1.425-1.231-1.613zm-.438 2.444a.835.835 0 0 1 0-1.668.83.83 0 0 1 .831.831.828.828 0 0 1-.831.837z"/></svg> --> */}
                        </div>
                        Cloud Reseller
                      </a>
                    </div>
                    <div class="heading pt-2">
                      <a
                        href="#gotop"
                        class="gocheck showSingle seccolor"
                        target="3"
                      >
                        <div class="lazyload">
                          {/* <!-- <svg class="svg me-3" width="32" height="32" viewBox="0 0 32 32"><path id="svg-ico" fill="#ee5586" d="M24.68 17.74a1.68 1.68 0 1 0 0 3.36 1.68 1.68 0 0 0 0-3.36zm0 2.53a.84.84 0 1 1 0-1.68.84.84 0 1 1 0 1.68z"/><path fill="#5e686c" d="M29.54 13.54H2.46c-1.12 0-2.02.84-2.02 1.86v8.05c0 1.03.9 1.86 2.02 1.86h27.08c1.12 0 2.02-.83 2.02-1.86V15.4c0-1.02-.9-1.86-2.02-1.86zm1.18 9.91c0 .56-.53 1.02-1.18 1.02H2.46c-.65 0-1.18-.45-1.18-1.02V15.4c0-.56.53-1.03 1.18-1.03h27.08c.65 0 1.18.46 1.18 1.03v8.05z"/><path id="svg-ico" fill="#ee5586" d="M24.68 4.66a1.68 1.68 0 1 0 0 3.36 1.68 1.68 0 0 0 0-3.36zm0 2.53c-.46 0-.84-.38-.84-.85s.37-.83.84-.83.85.37.85.83c0 .47-.39.85-.85.85z"/><path fill="#5e686c" d="M29.54.46H2.46C1.34.46.44 1.29.44 2.32v8.05c0 1.02.9 1.86 2.02 1.86h27.08c1.12 0 2.02-.84 2.02-1.86V2.32c0-1.03-.9-1.86-2.02-1.86zm1.18 9.9c0 .57-.53 1.03-1.18 1.03H2.46c-.65 0-1.18-.45-1.18-1.02V2.32c0-.56.53-1.03 1.18-1.03h27.08c.65 0 1.18.46 1.18 1.03v8.05zM25.99 29.8h-8.36a1.7 1.7 0 0 0-1.2-1.21V26.7a.42.42 0 0 0-.84 0v1.88c-.59.15-1.06.62-1.2 1.21H6a.42.42 0 0 0 0 .84h8.36a1.69 1.69 0 0 0 3.26 0H26a.42.42 0 0 0 0-.84zM16 31.06a.84.84 0 1 1 0-1.69.84.84 0 0 1 0 1.7z"/></svg> --> */}
                        </div>
                        Dedicated Servers
                      </a>
                    </div>
                    <div class="heading pt-2">
                      <a
                        href="#gotop"
                        class="gocheck showSingle seccolor"
                        target="4"
                      >
                        <div class="lazyload">
                          {/* <!-- <svg class="svg me-3" width="32" height="32" viewBox="0 0 32 32"><path fill="#5e686b" d="M16 31.813c-6.348 0-11.512-5.164-11.512-11.512S9.652 8.789 16 8.789c6.348 0 11.512 5.164 11.512 11.512S22.348 31.813 16 31.813zm0-22.172c-5.878 0-10.659 4.782-10.659 10.659S10.123 30.96 16 30.96c5.878 0 10.659-4.782 10.659-10.66S21.877 9.641 16 9.641z"/><path id="svg-ico" fill="#ee5586" d="M16 24.04a.426.426 0 0 1-.426-.426v-5.116a.426.426 0 1 1 .852 0v5.117a.426.426 0 0 1-.426.426zm5.29-16.16a.426.426 0 0 1-.426-.426v-3.4a2.98 2.98 0 0 0-2.979-2.975h-3.768a2.98 2.98 0 0 0-2.979 2.975v3.4a.426.426 0 1 1-.852 0v-3.4A3.834 3.834 0 0 1 14.118.227h3.768a3.834 3.834 0 0 1 3.832 3.827v3.4a.426.426 0 0 1-.427.426z"/></svg> --> */}
                        </div>
                        SSL Certificates
                      </a>
                    </div>
                    <div class="heading pt-2">
                      <a
                        href="#gotop"
                        class="gocheck showSingle seccolor"
                        target="5"
                      >
                        <div class="lazyload">
                          {/* <!-- <svg class="svg me-3" width="32" height="32"><path fill="#5e686b" d="M29.55 13.35H13.37a.42.42 0 0 0 0 .84h16.18c.65 0 1.18.45 1.18 1.02v8.05c0 .57-.53 1.03-1.18 1.03H2.47c-.65 0-1.18-.46-1.18-1.03v-8.05c0-.56.53-1.02 1.18-1.02h4.17a.42.42 0 0 0 0-.84H2.47c-1.11 0-2.03.84-2.03 1.86v8.05c0 1.03.91 1.87 2.03 1.87h27.08c1.11 0 2.02-.84 2.02-1.87v-8.05c0-1.02-.9-1.86-2.02-1.86zM29.55.27H13.37a.42.42 0 0 0 0 .84h16.18c.65 0 1.18.45 1.18 1.02v8.05c0 .57-.53 1.03-1.18 1.03H2.47c-.65 0-1.18-.46-1.18-1.03V2.13c0-.56.53-1.02 1.18-1.02h4.17a.42.42 0 0 0 0-.84H2.47C1.36.27.45 1.1.45 2.13v8.05c0 1.03.9 1.86 2.03 1.86h27.08c1.1 0 2.02-.83 2.02-1.86V2.13c0-1.02-.92-1.86-2.03-1.86z"/><path id="svg-ico" d="M25.77 19.66a.42.42 0 0 0 0-.84h-2.16a.42.42 0 0 0 0 .84h2.16zM25.77 6.58a.42.42 0 0 0 0-.84h-2.16a.42.42 0 0 0 0 .83h2.16z"/><path fill="#5e686b" d="M26 29.61h-8.36a1.7 1.7 0 0 0-1.2-1.2v-1.88a.42.42 0 0 0-.85 0v1.87c-.58.15-1.05.62-1.2 1.21H6.02a.42.42 0 0 0 0 .84h8.36a1.69 1.69 0 0 0 3.26 0H26c.23 0 .42-.19.42-.42 0-.23-.2-.42-.43-.42zM16 30.88a.84.84 0 1 1 0-1.7.84.84 0 0 1 0 1.7z"/></svg> --> */}
                        </div>
                        Cloud VPS
                      </a>
                    </div>
                  </div>
                </aside>
              </div>
              <div class="pt-35 col-md-12 col-lg-8">
                <div id="sidebar_content" class="wrap-blog">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 knowledge">
                      <div
                        id="div3"
                        class="wrapper targetDiv mt-5 bg-seccolorstyle noshadow"
                      >
                        <a href="/" class="category h4">
                          <b>Dedicated Servers</b>
                        </a>
                        <span class="float-end c-grey seccolor">
                          [Questions 4]
                        </span>
                        <hr />
                        <a class="h5" href="/">
                          1. What is a Dedicated Server?{" "}
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            Lorem ipsum, or lipsum as it is sometimes known, is
                            dummy text used in laying out print, graphic or web
                            designs. The passage is
                            <i class="golink">attributed to an unknown</i>
                            typesetter in
                            <span class="text-dark seccolor">
                              the 15th century who is thought to have scrambled
                              parts of Cicero's De Finibus Bonorum et Malorum
                              for use in a type specimen book. It usually begins
                              with.
                            </span>
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          2. How to install Control Panel?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            From its medieval origins to the digital era, learn
                            everything there is to know about the ubiquitous
                            lorem ipsum passage.
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          3. I can make specific configurations?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            Lorem ipsum, or lipsum as it is sometimes known, is
                            dummy text used in laying out print, graphic or web
                            designs. The passage is
                            <i class="golink">attributed</i> to an unknown
                            typesetter in the 15th century who is thought to
                            have scrambled parts of Cicero's De Finibus Bonorum
                            et Malorum for use in a type specimen book. It
                            usually begins with
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          4. Can I change the Operating System?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            From its medieval origins to the digital era, learn
                            everything there is to know about the ubiquitous
                            lorem ipsum passage.
                          </p>
                        </div>
                      </div>
                      <div
                        id="div2"
                        class="wrapper targetDiv mt-5 bg-seccolorstyle noshadow"
                      >
                        <a href="/" class="category h4">
                          <b>Cloud Reseller</b>
                        </a>
                        <span class="float-end c-grey seccolor">
                          [Questions 3]
                        </span>
                        <hr />
                        <a class="h5" href="/">
                          1. What exactly is Cloud Reseller?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            Lorem ipsum, or lipsum as it is sometimes known, is
                            dummy text used in laying out print, graphic or web
                            designs. The passage is
                            <i class="golink">attributed to an unknown</i>
                            typesetter in
                            <span class="text-dark seccolor">
                              the 15th century who is thought to have scrambled
                              parts of Cicero's De Finibus Bonorum et Malorum
                              for use in a type specimen book. It usually begins
                              with.
                            </span>
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          2. Is there a brandable control panel?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            From its medieval origins to the digital era, learn
                            everything there is to know about the ubiquitous
                            lorem ipsum passage.
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          3. Can my Linux Reseller plan be upgraded when needed?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            Lorem ipsum, or lipsum as it is sometimes known, is
                            dummy text used in laying out print, graphic or web
                            designs. The passage is
                            <i class="golink">attributed</i> to an unknown
                            typesetter in the 15th century who is thought to
                            have scrambled parts of Cicero's De Finibus Bonorum
                            et Malorum for use in a type specimen book. It
                            usually begins with
                          </p>
                        </div>
                      </div>
                      <div
                        id="div1"
                        class="wrapper targetDiv mt-5 bg-seccolorstyle noshadow"
                      >
                        <a href="/" class="category h4">
                          <b>Shared Hosting</b>
                        </a>
                        <span class="float-end c-grey seccolor">
                          [Questions 4]
                        </span>
                        <hr />
                        <a class="h5" href="/">
                          1. What is Shared Hosting?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            Lorem ipsum, or lipsum as it is sometimes known, is
                            dummy text used in laying out print, graphic or web
                            designs. The passage is
                            <i class="golink">attributed to an unknown</i>
                            typesetter in
                            <span class="text-dark seccolor">
                              the 15th century who is thought to have scrambled
                              parts of Cicero's De Finibus Bonorum et Malorum
                              for use in a type specimen book. It usually begins
                              with.
                            </span>
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          2. Does Shared Web Hosting include Email Hosting as
                          well?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            From its medieval origins to the digital era, learn
                            everything there is to know about the ubiquitous
                            lorem ipsum passage.
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          3. Is an upgrade possible from my current Linux Shared
                          Hosting Plan?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            Lorem ipsum, or lipsum as it is sometimes known, is
                            dummy text used in laying out print, graphic or web
                            designs. The passage is
                            <i class="golink">attributed</i> to an unknown
                            typesetter in the 15th century who is thought to
                            have scrambled parts of Cicero's De Finibus Bonorum
                            et Malorum for use in a type specimen book. It
                            usually begins with
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          4. Is there any money back guarantee with the Linux
                          Web Hosting services?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            From its medieval origins to the digital era, learn
                            everything there is to know about the ubiquitous
                            lorem ipsum passage.
                          </p>
                        </div>
                      </div>
                      <div
                        id="div4"
                        class="wrapper targetDiv mt-5 bg-seccolorstyle noshadow"
                      >
                        <a href="/" class="category h4">
                          <b>SSL Certificates</b>
                        </a>
                        <span class="float-end c-grey seccolor">
                          [Questions 2]
                        </span>
                        <hr />
                        <a class="h5" href="/">
                          1. What is SSL Certificates?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            Lorem ipsum, or lipsum as it is sometimes known, is
                            dummy text used in laying out print, graphic or web
                            designs. The passage is
                            <i class="golink">attributed to an unknown</i>
                            typesetter in
                            <span class="text-dark seccolor">
                              the 15th century who is thought to have scrambled
                              parts of Cicero's De Finibus Bonorum et Malorum
                              for use in a type specimen book. It usually begins
                              with.
                            </span>
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          2. How to install SSL Certificate?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            From its medieval origins to the digital era, learn
                            everything there is to know about the ubiquitous
                            lorem ipsum passage.
                          </p>
                        </div>
                      </div>
                      <div
                        id="div5"
                        class="wrapper targetDiv mt-5 bg-seccolorstyle noshadow"
                      >
                        <a href="/" class="category h4">
                          <b>Cloud VPS</b>
                        </a>
                        <span class="float-end c-grey seccolor">
                          [Questions 4]
                        </span>
                        <hr />
                        <a class="h5" href="/">
                          1. Do the Virtual Private Servers have a backup
                          policy?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            Lorem ipsum, or lipsum as it is sometimes known, is
                            dummy text used in laying out print, graphic or web
                            designs. The passage is
                            <i class="golink">attributed to an unknown</i>
                            typesetter in
                            <span class="text-dark seccolor">
                              the 15th century who is thought to have scrambled
                              parts of Cicero's De Finibus Bonorum et Malorum
                              for use in a type specimen book. It usually begins
                              with.
                            </span>
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          2. What are the benefits of Cloud VPS?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            From its medieval origins to the digital era, learn
                            everything there is to know about the ubiquitous
                            lorem ipsum passage.
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          3. Is Email Hosting included?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            Lorem ipsum, or lipsum as it is sometimes known, is
                            dummy text used in laying out print, graphic or web
                            designs. The passage is
                            <i class="golink">attributed</i> to an unknown
                            typesetter in the 15th century who is thought to
                            have scrambled parts of Cicero's De Finibus Bonorum
                            et Malorum for use in a type specimen book. It
                            usually begins with
                          </p>
                        </div>
                        <br />
                        <a class="h5" href="/">
                          4. What type of support do you offer?
                        </a>
                        <div class="blog-info">
                          <p class="seccolor">
                            From its medieval origins to the digital era, learn
                            everything there is to know about the ubiquitous
                            lorem ipsum passage.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ***** HELP ***** --> */}
        <section class="services help pt-4 pb-80 bg-colorstyle">
          <div class="container">
            <div class="service-wrap">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-seccolorstyle noshadow">
                    <a href="/" class="help-item">
                      <div class="img">
                        <div class="lazyload">
                          {/* <!-- <svg class="svg ico" width="32" height="32" viewBox="0 0 32 32"><path fill="#5e686c" d="M12.82 9.14a.46.46 0 0 1-.46-.43c-.18-2.55-2.62-4.55-5.54-4.55a.46.46 0 0 1 0-.91c3.4 0 6.24 2.37 6.46 5.4a.46.46 0 0 1-.43.5l-.03-.01z"/><path fill="#5e686c" d="M3.4 14.99a.65.65 0 0 1-.66-.64l-.15-.94A5.53 5.53 0 0 1 .36 9.03c0-3.2 2.9-5.79 6.47-5.79.26 0 .46.21.46.46 0 .26-.2.46-.46.46-3.06 0-5.55 2.18-5.55 4.87 0 1.45.73 2.81 2 3.75.1.06.17.18.19.3l.14.87.87-.37c.1-.05.22-.05.33-.02 1.07.37 2.27.43 3.37.2a.46.46 0 0 1 .2.9c-1.2.25-2.51.2-3.7-.16l-1 .43a.67.67 0 0 1-.27.06z"/><path id="svg-ico" fill="#ee5586" d="M26.22 28.8a.88.88 0 0 1-.43-.13l-2.08-.88c-1.37.44-2.8.67-4.24.67-6.77 0-12.28-4.9-12.28-10.94s5.5-10.94 12.28-10.94c6.77 0 12.28 4.9 12.28 10.94 0 3.24-1.59 6.28-4.37 8.37l-.34 2.14c0 .4-.36.76-.82.76zm-2.49-1.96c.06 0 .13 0 .18.03l2.25.97.35-2.28a.47.47 0 0 1 .18-.3 9.58 9.58 0 0 0 4.14-7.74c0-5.52-5.1-10.01-11.36-10.01S8.1 12 8.1 17.52s5.1 10.02 11.36 10.02a12.78 12.78 0 0 0 4.26-.7z"/><path fill="#5e686c" d="M24.56 14.99H13.83c-.25 0-.45-.2-.45-.46s.2-.45.45-.45h10.73c.26 0 .46.2.46.45s-.2.46-.46.46zm0 3.19H13.83c-.25 0-.45-.2-.45-.45s.2-.46.45-.46h10.73c.26 0 .46.2.46.46s-.2.45-.46.45zm0 3.13H13.83a.45.45 0 0 1-.45-.45c0-.26.2-.46.45-.46h10.73c.26 0 .46.2.46.46 0 .25-.2.45-.46.45z"/></svg> --> */}
                        </div>
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Live Chat</div>
                        <div class="description seccolor">
                          Lorem Ipsum is simply dummy text printing.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-seccolorstyle noshadow">
                    <a href="contact" class="help-item">
                      <div class="img">
                        <div class="lazyload">
                          {/* <!-- <svg class="svg ico" width="32" height="32" viewBox="0 0 32 32"><path fill="#5e686c" d="M29.6 31.9H2.4A2 2 0 0 1 .5 30V12c0-1 .9-1.8 1.9-1.8.2 0 .4.2.4.4s-.2.5-.4.5a1 1 0 0 0-1 1V30c0 .6.4 1 1 1h27.2c.6 0 1-.4 1-1V12c0-.5-.4-1-1-1-.2 0-.4-.1-.4-.4s.2-.4.4-.4c1 0 1.9.9 1.9 1.9v18c0 1-.8 1.8-1.9 1.8z"/><path fill="#5e686c" d="M1.7 31.1h-.3a.4.4 0 0 1 0-.6l10-13c.1-.1.4-.2.6 0 .2.1.2.4 0 .6L2 31l-.3.1zm28.7 0L30 31 20 18a.4.4 0 0 1 0-.5c.2-.2.5-.1.7 0l10 13v.5l-.3.1z"/><path id="svg-ico" fill="#ee5586" d="M16 21.1h-.2L2 11a.4.4 0 0 1 0-.6c0-.2.4-.2.6 0L16 20.1l13.4-9.9h.6c.2 0 0 .6-.1.7L16.3 21l-.3.1z"/><path id="svg-ico" fill="#ee5586" d="M29.6 11h-.2L16 1 2.6 11c-.1.1-.4 0-.5-.1v-.6L15.8.3h.5l13.6 10c.2.1.2.4 0 .6 0 .1-.1.2-.3.2z"/></svg> --> */}
                        </div>
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Send Ticket</div>
                        <div class="description seccolor">
                          Lorem Ipsum is simply dummy text printing.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-4">
                  <div class="help-container bg-seccolorstyle noshadow">
                    <a href="knowledgebase-list" class="help-item">
                      <div class="img">
                        <div class="lazyload">
                          {/* <!-- <svg class="svg ico" width="32" height="32" viewBox="0 0 32 32"><path fill="#5e686b" d="M27.06 5.16c-2.6-2.6-6.05-4.2-9.7-4.5a.42.42 0 1 0-.07.84 14.73 14.73 0 0 1 9.18 25.06 14.73 14.73 0 0 1-25.12-10.4A14.68 14.68 0 0 1 14.86 1.5c.23 0 .4-.2.39-.44a.42.42 0 0 0-.46-.4 15.54 15.54 0 0 0-9.73 26.5 15.52 15.52 0 0 0 22 0 15.58 15.58 0 0 0 0-22z"/><path id="svg-ico" fill="#ee5586" d="M17.56 16.26c-1.97 1.97-2.75 2.21-2.94 2.24l-.11-.11c-.58-.58-1.17-1.18-2.14-1.18-.74 0-1.56.38-2.58 1.19-.9.72-1.34 1.45-1.37 2.23-.03.56.17 1.04.46 1.46a5.18 5.18 0 0 0-2.17 2.61c-.08.22.04.46.26.54a.42.42 0 0 0 .53-.27 4.42 4.42 0 0 1 1.92-2.22l.47.46.3.29c.43.42 1.08.65 1.88.65 1.1 0 2.5-.42 3.93-1.17 1.6-.82 3.23-2.06 4.72-3.56a18.11 18.11 0 0 0 4.19-6.11c.34-.92.52-1.77.53-2.5 0-.82-.22-1.5-.65-1.92l-.28-.3c-.6-.62-1.4-1.48-2.5-1.48-.81 0-1.57.45-2.32 1.38-.93 1.17-1.3 2.05-1.17 2.88.11.8.64 1.32 1.16 1.84l.12.12c-.01.1-.1.35-.36.76-.37.57-1 1.3-1.88 2.17zm2.9-3.49-.19-.18c-.45-.44-.84-.83-.91-1.35-.09-.57.22-1.26 1-2.22.57-.71 1.11-1.06 1.65-1.06.73 0 1.35.64 1.88 1.2l.3.32c.27.27.4.73.4 1.33a6.6 6.6 0 0 1-.48 2.2 17.3 17.3 0 0 1-3.99 5.82 18.9 18.9 0 0 1-4.52 3.4 8.07 8.07 0 0 1-3.53 1.08c-.57 0-1.03-.15-1.28-.4l-.32-.3c-.58-.56-1.24-1.18-1.2-1.95.01-.52.36-1.05 1.05-1.6.85-.68 1.52-1.02 2.04-1.02.62 0 1.02.42 1.59 1l.12.11c.59.59 1.96-.17 4.08-2.3.91-.9 1.58-1.69 1.99-2.32.55-.82.65-1.42.32-1.75z"/></svg> --> */}
                        </div>
                      </div>
                      <div class="inform">
                        <div class="title mergecolor">Phone Now</div>
                        <div class="description seccolor">
                          Lorem Ipsum is simply dummy text printing.
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML ***** --> */}
        {/* <footer id="footer"></footer> */}
        <Footer />
      </div>
      {/* <!-- ***** BUTTON GO TOP ***** --> */}
      {/* <a href="/" class="cd-top" title="Go Top">
        <i class="fas fa-angle-up"></i>
      </a> */}
    </>
  );
};

export default Faq;
