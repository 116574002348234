import React from "react";

const Payment = () => {
  return (
    <div class="box-container limit-width">
      {/* <!-- ***** SETTINGS ****** --> */}
      <section id="settings"> </section>
      {/* <!-- ***** LOADING PAGE ****** --> */}
      <div id="spinner-area">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
          <div class="spinner-txt">Antler...</div>
        </div>
      </div>
      {/* <!-- ***** FRAME MODE ****** --> */}
      <div class="body-borders" data-border="20">
        <div class="top-border bg-white"></div>
        <div class="right-border bg-white"></div>
        <div class="bottom-border bg-white"></div>
        <div class="left-border bg-white"></div>
      </div>
      {/* <!-- ***** UPLOADED MENU FROM HEADER.HTML ***** --> */}
      <header id="header"> </header>
      {/* <!-- ***** BANNER ***** --> */}
      <div class="top-header exapath-w">
        <div class="total-grad-inverse"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="wrapper">
                <div class="heading">Payment Methods</div>
                <div class="subheading">
                  As your business expands into local regions where alternative
                  payments are popular, you don’t want to risk losing sales by
                  saying no. Antler gives you the ability to accept more payment
                  types without hesitation, so you can keep on growing.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- ***** PAYMENT METHODS ***** --> */}
      <section class="services sec-normal bg-colorstyle">
        <div class="container">
          <div class="service-wrap">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="sec-main sec-bg1 pay bg-seccolorstyle noshadow">
                  <div class="plans badge feat bg-purple">card system</div>
                  <div class="content-pay text-start">
                    <div class="lazyload">
                      {/* <!-- <img src="./assets/img/payments/visa.svg" class="bg-seccolorstyle" alt="VISA" > */}
                      {/* <img src="./assets/img/payments/mastercard.svg" class="bg-seccolorstyle" alt="Mastercard" /> */}
                      {/* <img src="./assets/img/payments/americanexpress.svg" class="bg-seccolorstyle" alt="American Express"> --> */}
                    </div>
                  </div>
                  <h2 class="section-heading mt-3 mergecolor">
                    Payment via Credit Card
                  </h2>
                  <p class="section-subheading mb-4 seccolor">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <p class="seccolor">
                    <span class="c-pink">●</span> Lorem ipsum dolor sit amet,
                    consectetur{" "}
                    <a href="/" class="golink">
                      adipiscing elit
                    </a>
                    , sed do eiusmod tempor.
                  </p>
                  <p class="seccolor">
                    <span class="c-pink">●</span> But I must explain to you how
                    all this mistaken idea of denouncing pleasure and praising
                    pain.
                  </p>
                  <p class="seccolor">
                    <span class="c-pink">●</span> Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit.
                  </p>
                  <div class="mt-5">
                    <a href="/" class="golink">
                      More information about Credit Card System
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="services sec-normal motpath bg-colorstyle">
        <div class="container">
          <div class="service-wrap">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <div class="sec-main sec-bg1 pay bg-seccolorstyle noshadow">
                  <div class="plans badge feat bg-purple">online system</div>
                  <div class="content-pay text-start">
                    <div class="lazyload">
                      {/* <!-- <img src="./assets/img/payments/paypal.svg" class="bg-seccolorstyle" alt="Paypal"> */}
                      {/* <img src="./assets/img/payments/payoneer.svg" class="bg-seccolorstyle" alt="Payonner"> */}
                      {/* <img src="./assets/img/payments/alipay.svg" class="bg-seccolorstyle" alt="Alypay"> --> */}
                    </div>
                  </div>
                  <h2 class="section-heading mt-3 mergecolor">
                    Payment via Digital Wallets
                  </h2>
                  <p class="section-subheading mb-4 seccolor">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <p class="seccolor">
                    <span class="c-pink"> [Step 01]</span> Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor.
                  </p>
                  <p class="seccolor">
                    <span class="c-pink">[Step 02]</span> But I must explain to
                    you how all this mistaken idea of{" "}
                    <a href="/" class="golink">
                      denouncing pleasure
                    </a>{" "}
                    and praising pain.
                  </p>
                  <p class="seccolor">
                    <span class="c-pink">[Step 03]</span> Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit.
                  </p>
                  <div class="mt-5">
                    <a href="/" class="golink">
                      More information about Digital Wallets System
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** PAYMENT TYPES ***** --> */}
      <section class="services sec-normal sec-bg2 bg-seccolorstyle">
        <div class="container">
          <div class="service-wrap">
            <div class="row">
              <div class="col-sm-12 text-start">
                <h2 class="section-heading mergecolor">
                  Multiple Payment Types
                </h2>
                <p class="section-subheading mergecolor">
                  Different customers like to pay in different ways, and Antler
                  lets you embrace them all. From international payment card
                  providers like Visa and American Express to digital wallets
                  and platforms like PayPal and WeChat Pay, we make it simple
                  for you to complete transactions with ease.
                </p>
              </div>
              <div class="col-md-12 pb-0 pt-5 seccolor h4">
                Major debit and credit cards
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    <img src="./assets/img/payments/visa.svg" alt="Visa" />
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Visa
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/mastercard.svg" alt="Mastercard"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Mastercard
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/americanexpress.svg" alt="American Express"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    American
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/discover.svg" alt="Discover"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Discover
                  </a>
                </div>
              </div>
              <div class="col-md-12 pb-0 pt-5 mergecolor h4">
                Pass-through digital wallets
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/visacheckout.svg" alt="Visa Checkout"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Visa Checkout
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="plans badge feat bg-purple">Google</div>
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/googlepay.svg" alt="Google Pay"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Google Pay
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="plans badge feat bg-purple">Apple</div>
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/applepay.svg" alt="Apple Pay"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Apple Pay
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/samsungpay.svg" alt="Samsung Pay"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Samsung Pay
                  </a>
                </div>
              </div>
              <div class="col-md-12 pb-0 pt-5 mergecolor h4">
                Alternative payment methods
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/sepa.svg" alt="SEPA"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Sepa
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/sofort.svg" alt="Sofort"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Sofort
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/boleto.svg" alt="Boleto"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Boleto
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/ideal.svg" alt="Ideal"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    iDeal
                  </a>
                </div>
              </div>
              <div class="col-md-12 pb-0 pt-5 mergecolor h4">
                Stored-value digital wallets
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/paypal.svg" alt="Paypal"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Paypal
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/payoneer.svg" alt="Payoneer"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Payoneer
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/chatpay.svg" alt="Chat Pay"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Wechatpay
                  </a>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3">
                <div class="service-section bg-colorstyle noshadow">
                  <div class="lazyload">
                    {/* <!-- <img src="./assets/img/payments/alipay.svg" alt="Alipay"> --> */}
                  </div>
                  <hr />
                  <p class="subtitle seccolor">
                    Excepteur sint occaecat cupidatat
                  </p>
                  <a href="/" class="btn btn-default-yellow-fill">
                    Alipay
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** MAP ***** --> */}
      <section class="services maping sec-normal item17 overlay bg-colorstyle">
        <div class="container">
          <div class="service-wrap">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h2 class="section-heading text-white">
                  Our Datacenters are Located in 7 Regions
                </h2>
                <p class="section-subheading text-light">
                  <span class="golink">Lorem ipsum dolor</span> sit amet,
                  consectetur adipiscing elit sed, do eiusmod tempor{" "}
                  <span class="c-pink">incididunt lorem ipsum</span> dolor sit
                  amet
                </p>
              </div>
              <div class="col-md-12 pt-5 position-relative">
                <div class="lazyload">
                  {/* <!--<img src="assets/patterns/map.svg" class="w-10" alt="Load Balancing">--> */}
                </div>
                <span
                  class="datacenters montreal"
                  data-bs-toggle="popover"
                  data-bs-container="body"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Montreal"
                  data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                  role="button"
                  aria-label="Montreal"
                ></span>
                <span
                  class="datacenters newyork"
                  data-bs-toggle="popover"
                  data-bs-container="body"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="New York"
                  data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                  role="button"
                  aria-label="New York"
                ></span>
                <span
                  class="datacenters portugal"
                  data-bs-toggle="popover"
                  data-bs-container="body"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Portugal"
                  data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                  role="button"
                  aria-label="Portugal"
                ></span>
                <span
                  class="datacenters london"
                  data-bs-toggle="popover"
                  data-bs-container="body"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="London"
                  data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                  role="button"
                  aria-label="London"
                ></span>
                <span
                  class="datacenters moskow"
                  data-bs-toggle="popover"
                  data-bs-container="body"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Moskow"
                  data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                  role="button"
                  aria-label="Moskow"
                ></span>
                <span
                  class="datacenters hongkong"
                  data-bs-toggle="popover"
                  data-bs-container="body"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Hong Kong"
                  data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                  role="button"
                  aria-label="Hong Kong"
                ></span>
                <span
                  class="datacenters singapure"
                  data-bs-toggle="popover"
                  data-bs-container="body"
                  data-bs-trigger="hover"
                  data-bs-placement="top"
                  title="Singapure"
                  data-bs-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
                  role="button"
                  aria-label="Singapure"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** CONTACT FORM ***** --> */}
      <section
        id="ticket"
        class="bg-colorstyle toppadding pb-80 bottomhalfpadding"
      >
        <div class="container">
          <div class="sec-main sec-up mb-0 sec-bg1 bg-colorstyle nomargin nopadding noshadow">
            <div class="randomline hideelement">
              <div class="bigline"></div>
              <div class="smallline"></div>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-12 cd-filter-block mb-0">
                <div class="form-contact cd-filter-content p-0 sec-bx">
                  <div class="text-center">
                    <h2 class="section-heading mergecolor">
                      Full out the Contact form to contact us
                    </h2>
                    <p class="mergecolor">
                      We Will Help You To Choose The Best Plan!
                    </p>
                  </div>
                  <form id="contactForm" method="POST">
                    <div class="row">
                      <div class="col-md-6 position-relative">
                        <label>
                          <i class="fas fa-user-tie"></i>
                        </label>
                        <input
                          id="name"
                          type="text"
                          name="name"
                          placeholder="Full Name"
                          required=""
                        />
                      </div>
                      <div class="col-md-6 position-relative">
                        <label>
                          <i class="fas fa-envelope"></i>
                        </label>
                        <input
                          id="email"
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          required=""
                        />
                      </div>
                      <div class="col-md-6 position-relative">
                        <label>
                          <i class="fas fa-file-alt"></i>
                        </label>
                        <input
                          id="subject"
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          required
                        />
                      </div>
                      <div class="col-md-6 position-relative">
                        <div class="cd-select mt-4">
                          <label class="db"></label>
                          <select
                            id="department"
                            name="department"
                            class="select-filter"
                            aria-label="Choose Department"
                          >
                            <option value="">Choose Department</option>
                            <option value="Support/Help Desk">
                              Support/Help Desk
                            </option>
                            <option value="Commercial Department">
                              Commercial Department
                            </option>
                            <option value="Sales Department">
                              Sales Department
                            </option>
                            <option value="Schedule Metings">
                              Schedule Metings
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6 position-relative">
                        <div class="form-group mt-4">
                          <textarea
                            id="message"
                            name="message"
                            class="form-control"
                            rows="5"
                            placeholder="Message..."
                          ></textarea>
                        </div>
                      </div>
                      <div class="col-md-6 position-relative mt-5">
                        <input
                          name="newsletter"
                          type="checkbox"
                          id="checkbox"
                          class="filter"
                        />
                        <label
                          for="checkbox"
                          class="checkbox-label c-grey mb-4 seccolor"
                        >
                          <span>
                            I have read and accept the terms of the privacy
                            policy -{" "}
                            <a href="legal" class="golink">
                              RGPD
                            </a>
                          </span>
                        </label>
                        <button
                          type="submit"
                          value="Submit"
                          class="btn btn-default-yellow-fill float-start me-3"
                        >
                          Submit Ticket
                        </button>
                        <button
                          type="reset"
                          value="reset"
                          class="btn btn-default-fill mt-0 mb-3 me-3"
                        >
                          Reset
                        </button>
                        <br />
                      </div>
                      <div id="msgSubmit" class="col-md-12 mt-4">
                        <h3 class="c-pink"> Message Submitted!</h3>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ***** UPLOADED FOOTER FROM FOOTER.HTML ***** --> */}
      <footer id="footer"> </footer>
    </div>
  );
};

export default Payment;
