import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkout/Checkout";
import CompanyPolicy from "./pages/companyPolicy/CompanyPolicy";
import Contact from "./pages/contact/Contact";
import Faq from "./pages/faq/Faq";
import FixIt from "./pages/fixIt/FixIt";

import "./app.css";
import "./index.css"
import PaymentPolicy from "./pages/paymentPolicy/PaymentPolicy";
import Payment from "./pages/payment/Payment";
import Portfolio from "./pages/portfolio/Portfolio";
import Pricing from "./pages/pricing/Pricing";
import Result from "./pages/result/Result";
import Error from "./pages/error/Error";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/cart",
      element: <Cart />,
    },
    {
      path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/companypolicy",
      element: <CompanyPolicy />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/faq",
      element: <Faq />,
    },
    {
      path: "/fix-it",
      element: <FixIt />,
    },
    {
      path: "/payment-policy",
      element: <PaymentPolicy />,
    },
    {
      path: "/payment",
      element: <Payment />,
    },
    {
      path: "/portfolio",
      element: <Portfolio />,
    },
    {
      path: "/pricing",
      element: <Pricing />,
    },
    {
      path: "/result",
      element: <Result />,
    },
    {
      path: "/error",
      element: <Error />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
